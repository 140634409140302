import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'
import { toPercentString } from '../../../helpers/convert'

export const groupsGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Group ID',
        field: 'id',
        pinned: true
      },
      {
        headerName: 'Version',
        field: 'version',
        pinned: true,
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Info',
        field: 'version_info',
        minWidth: 450,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
        pinned: true
      },
      {
        headerName: 'MO %',
        field: 'margin_objective',
        pinned: true,
        cellRenderer: ({ value }) => toPercentString(value),
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Enable?',
        field: 'is_enabled',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.updateExperimentGroupPathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable Experiment Version' : 'Disable Experiment Version',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: '# Games',
        field: 'total_games'
      },
      {
        headerName: '# Android',
        field: 'android_games'
      },
      {
        headerName: '# iOS',
        field: 'ios_games'
      },
      {
        headerName: 'Predicted Margin',
        field: 'total_margin'
      },
      {
        headerName: 'Calendar Margin',
        field: 'calendar_margin'
      },
      {
        headerName: 'Σ Spends',
        field: 'total_spends'
      },
      {
        headerName: 'Σ Revenues',
        field: 'total_revenues'
      },
      {
        headerName: 'Σ Installs',
        field: 'total_installs'
      },
      {
        headerName: 'Σ Organic Installs',
        field: 'organic_installs'
      },
      {
        headerName: 'Android Margin',
        field: 'android_margin'
      },
      {
        headerName: 'iOS Margin',
        field: 'ios_margin'
      },
      {
        headerName: 'Android Spends',
        field: 'android_spends'
      },
      {
        headerName: 'iOS Spends',
        field: 'ios_spends'
      },
      {
        headerName: 'Android Installs',
        field: 'android_installs'
      },
      {
        headerName: 'iOS Installs',
        field: 'ios_installs'
      },
      {
        headerName: 'US Margin',
        field: 'us_margin'
      },
      {
        headerName: 'Non-US Margin',
        field: 'non_us_margin'
      },
      {
        headerName: 'US Spends',
        field: 'us_spends'
      },
      {
        headerName: 'Non-US Spends',
        field: 'non_us_spends'
      },
      {
        headerName: 'US Installs',
        field: 'us_installs'
      },
      {
        headerName: 'Non-US Installs',
        field: 'non_us_installs'
      }
    ],
    defaultColDef: {
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true,
      minWidth: 150
    },
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled,
      'border-yellow-300 bg-yellow-50 border-dashed': params => params.data.is_control_group
    },
    components: { CheckboxRenderer },
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
