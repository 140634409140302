import { toLocaleDateString, toBooleanString, toPercentString } from '../../../helpers/convert'
import { compareDates, valueExists } from '../../../helpers/check'

const existClass = (value) => {
  if (!valueExists(value)) {
    return 'text-yellow-500'
  }
  return value ? 'text-green-500' : 'text-red-500'
}

export const sentSummaryGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        pinned: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Date',
        field: 'date',
        pinned: true,
        cellRenderer: ({ value }) => toLocaleDateString(value),
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        }
      },
      {
        headerName: 'Sent File?',
        field: 'sent_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Accepted File?',
        field: 'accepted_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Rejected File?',
        field: 'rejected_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Sent Bids',
        field: 'sent_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Accepted Bids',
        field: 'accepted_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Rejected Bids',
        field: 'rejected_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Succeeded Bid Changes',
        field: 'succeeded_bid_changes',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Succeeded Changes %',
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
          const succeeded = params.data.succeeded_bid_changes
          const sent = params.data.sent_bids
          if (succeeded && sent && sent > 0) {
            return Math.round(succeeded * 100 / sent)
          }
          return null
        },
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: params => {
          const percent = params.value
          if (percent) {
            if (percent < 70) {
              return 'text-red-500'
            }
            if (percent < 90) {
              return 'text-yellow-500'
            }
          }
          return 'text-green-500'
        }
      },
      {
        headerName: 'Rejected Reasons',
        field: 'rejected_reasons',
        minWidth: 600,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      filter: true,
      minWidth: 125,
      autoHeight: true
    },
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
