export class ExperimentGroupsRenderer {
  init (params) {
    this.params = params

    const div0 = document.createElement('div')
    div0.className = 'flex flex-col'

    const div1 = document.createElement('div')
    div1.className = 'overflow-x-auto'

    const div2 = document.createElement('div')
    div2.className = 'align-middle inline-block min-w-full'

    const div3 = document.createElement('div')
    div3.className = 'shadow overflow-hidden border-b border-gray-200'

    const table = document.createElement('table')
    table.className = 'min-w-full divide-y divide-gray-200'

    const thead = table.createTHead()
    thead.className = 'bg-gray-50'

    const headerRow = thead.insertRow()
    ;['Games', 'Margin', 'Enabled?'].forEach(v => {
      const th = document.createElement('th')
      th.className =
        'px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
      const text = document.createTextNode(v)
      th.appendChild(text)
      headerRow.appendChild(th)
    })

    const tbody = table.createTBody()
    tbody.className = 'bg-white divide-y divide-gray-200'
    this.params.value.forEach(v => {
      const row = tbody.insertRow()
      row.className = 'px-6 py-4 whitespace-nowrap text-sm text-gray-500'
      if (v.is_control_group) {
        row.className += ' bg-yellow-50'
      }
      if (!v.is_enabled) {
        row.className += ' opacity-50'
      }
      const is_enabled = v.is_enabled ? '✔' : '✖'
      ;[v.total_games, v.total_margin, is_enabled].forEach(v => {
        const cell = row.insertCell()
        cell.className = 'px-1'
        const text = document.createTextNode(v)
        cell.appendChild(text)
      })
    })

    div3.appendChild(table)
    div2.appendChild(div3)
    div1.appendChild(div2)
    div0.appendChild(div1)

    this.eGui = div0
    this.params.element = this.eGui
  }

  getGui () {
    return this.eGui
  }

  destroy () {}
}
