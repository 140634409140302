import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'voodooPublishedApps',
    'id',
    'name',
    'platform',
    'abLaunchDate',
    'softLaunchDate',
    'fullLaunchDate'
  ]

  fillCommonFields = () => {
    const { id, game, platform, abLaunchDate, softLaunchDate, fullLaunchDate } =
        $(this.voodooPublishedAppsTarget).find(':selected').data()

    this.idTarget.value = id
    this.nameTarget.value = game
    this.platformTarget.value = platform
    this.abLaunchDateTarget.value = abLaunchDate || null
    this.softLaunchDateTarget.value = softLaunchDate || null
    this.fullLaunchDateTarget.value = fullLaunchDate || null
  }
}
