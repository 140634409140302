import { toLocaleDateString } from '../../../helpers/convert'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        minWidth: 100,
        pinned: true,
        cellRenderer: ({ value }) => {
          return `
            <div class="flex items-center">
                ${value} 
                <a href="/bid_batches/${value}" data-turbolinks="false" class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">                     
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                </a>
            </div>
          `
        }
      },
      {
        headerName: 'App ID',
        field: 'app_id',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        pinned: true
      },
      {
        headerName: 'App Name',
        field: 'app_name',
        minWidth: 200,
        pinned: true,
        filter: true,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Platform',
        field: 'platform',
        minWidth: 150
      },
      {
        headerName: 'Network',
        field: 'ad_network',
        filter: true,
        minWidth: 150,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Country',
        field: 'country',
        filter: true,
        minWidth: 100
      },
      {
        headerName: 'Batch Type',
        field: 'batch_type',
        filter: true,
        minWidth: 150,
        cellClassRules: {
          'text-green-500': params => params.value === 'reset_bids',
          'text-red-500': params => params.value === 'bids_correction'
        }
      },
      {
        headerName: 'Created By',
        field: 'created_by',
        minWidth: 150,
        cellRenderer: ({ value }) => value ? value.replace(/@.+/, '') : '-'
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 125,
        cellClassRules: {
          'text-green-500': params => params.value === '200',
          'text-yellow-500': params => params.value === '0',
          'text-red-500': params => params.value !== '200'
        }
      },
      {
        headerName: 'Details',
        field: 'details',
        minWidth: 400,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
        cellRenderer: ({ value }) => value.slice(0, 150)
      },
      {
        headerName: 'BidShake Batch ID',
        field: 'bidshake_batch_id',
        minWidth: 150
      },
      {
        headerName: 'Created At',
        field: 'created_at',
        minWidth: 150,
        cellRenderer: ({ value }) => toLocaleDateString(value)
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      autoHeight: true,
      filter: true
    },
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
