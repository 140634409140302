import { ActionButtons } from './action_buttons'
import { toPercentString, toLocaleDateString } from '../../../helpers/convert'
import { valueExists } from '../../../helpers/check'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Level',
        field: 'level',
        minWidth: 175,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        cellRenderer: ({ value }) => {
          const color = {
            campaign: 'green',
            country: 'red',
            country_group: 'yellow',
            country_group_network: 'blue',
            country_network: 'purple'
          }[value]

          return `
            <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${color}-100 text-${color}-800">
              ${value}
            </span>
          `
        }
      },
      {
        headerName: 'Country Group',
        valueGetter: params => valueExists(params.data.country_group) ? params.data.country_group : '-'
      },
      {
        headerName: 'Country',
        valueGetter: params => valueExists(params.data.country_code) ? `${params.data.country_code} - ${params.data.country_name || 'All'}` : '-',
        minWidth: 300,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Network',
        field: 'network_name',
        cellRenderer: ({ value }) => valueExists(value) ? value : '-'
      },
      {
        headerName: 'Campaign',
        field: 'campaign_name',
        cellRenderer: ({ value }) => valueExists(value) ? value : '-',
        minWidth: 300,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'LTV correction',
        field: 'ltv_correction',
        cellRenderer: ({ value }) => toPercentString(value * 100)
      },
      {
        headerName: 'Updated At',
        field: 'updated_at',
        filter: false,
        cellRenderer: ({ value }) => toLocaleDateString(value)
      }
    ],
    onRowSelected: async (params) => {
      controller.toggleBulkActions()
    },
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true,
      minWidth: 120
    },
    components: { ActionButtons },
    editType: 'fullRow',
    animateRows: true,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    rowSelection: 'multiple'
  }
}
