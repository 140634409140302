import { toPercentString, toRankString, toBooleanString } from '../../../helpers/convert'

export const relativeGridOptions = controller => {
  const switchback = controller.type === 'switchback'
  const prefix = switchback ? 'mean_' : ''

  return {
    columnDefs: [
      {
        headerName: 'Rank',
        field: 'rank',
        width: 100,
        cellRenderer: ({ value }) => toRankString(value)
      },
      {
        headerName: 'Group',
        field: 'group_id',
        width: 100,
        hide: switchback
      },
      {
        headerName: 'Version',
        field: 'version',
        width: 120
      },
      {
        headerName: 'Info',
        field: 'version_info',
        width: 400,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'MO %',
        field: 'margin_objective',
        width: 100,
        cellRenderer: ({ value }) => toPercentString(value)
      },
      {
        headerName: 'Is significant',
        field: 'is_significant',
        width: 150,
        cellRenderer: ({ value }) => toBooleanString(value),
        hide: !switchback
      },
      {
        headerName: 'P value',
        field: 'p_value',
        width: 150,
        cellRenderer: ({ value }) => value.toFixed(3),
        hide: !switchback
      },
      {
        headerName: 'Enabled?',
        field: 'is_enabled',
        width: 150,
        cellRenderer: ({ value }) => toBooleanString(value),
        hide: switchback
      },
      {
        headerName: '⇅ Pred Margin Δ %',
        field: `${prefix}relative_margin_change_percent`,
        width: 200,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: [
          'bg-yellow-50',
          'border-dotted',
          'border-yellow-200',
          'border-t-0',
          'border-b-0',
          'border-l-2',
          'border-r-2'
        ],
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: '⇅ Cal Margin Δ %',
        field: `${prefix}relative_calendar_margin_change_percent`,
        width: 200,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: [
          'bg-yellow-50',
          'border-dotted',
          'border-yellow-200',
          'border-t-0',
          'border-b-0',
          'border-l-2',
          'border-r-2'
        ],
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: '⇅ Spends Δ %',
        field: `${prefix}relative_spends_change_percent`,
        width: 200,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: '⇅ Installs Δ %',
        field: `${prefix}relative_installs_change_percent`,
        width: 200,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: '⇅ Organic Installs Δ %',
        field: `${prefix}relative_organic_installs_change_percent`,
        width: 200,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      }
    ],
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled,
      'border-yellow-300 bg-yellow-50 border-dashed': params => params.data.is_control_group
    },
    defaultColDef: {
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true
    },
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
