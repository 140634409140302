import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { gridOptions } from './index/grid_options'

export default class extends Controller {
  static targets = ['grid', 'modal', 'transition', 'closeButton', 'modalPlaceholder']
  static values = { getVersionsPath: String, versionUpdatePath: String }

  connect () {
    useDataGrid(this)
    this.newGrid(this.gridTarget, gridOptions(this), this.getVersionsPathValue)
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }
}
