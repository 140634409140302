import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'autobidApps',
    'appId',
    'appName',
    'platform',
    'batchType'
  ]

  fillCommonFields = () => {
    const { appId, appName, platform } = $(this.autobidAppsTarget).find(':selected').data()
    this.appIdTarget.value = appId
    this.appNameTarget.value = appName
    this.platformTarget.value = platform
  }
}
