import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'
import { toLocaleDateString } from '../../../helpers/convert'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        filter: true,
        width: 100
      },
      {
        headerName: 'Version',
        field: 'version',
        filter: true,
        width: 100
      },
      {
        headerName: 'Info',
        field: 'info',
        filter: true,
        width: 400,
        onCellValueChanged: ev => {
          // TODO check JSON in 'info' field is valid
          controller.updateRecord(ev, controller.versionUpdatePathValue)
        },
        editable: true,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Enabled?',
        field: 'is_enabled',
        filter: true,
        width: 100,
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.versionUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable Version' : 'Disable Version',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: 'Created At',
        field: 'created_at',
        width: 100,
        cellRenderer: ({ value }) => toLocaleDateString(value)
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      autoHeight: true
    },
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled
    },
    components: { CheckboxRenderer },
    animateRows: true,
    editType: 'fullRow',
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
