import { compareDates } from '../../../helpers/check'
import { ActionButtons } from '../../../helpers/action_buttons'

export const excludePeriodsGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        filter: 'agNumberColumnFilter',
        width: 125
      },
      {
        headerName: 'From',
        field: 'from_date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        }
      },
      {
        headerName: 'To',
        field: 'to_date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        }
      },
      {
        headerName: 'Reason',
        field: 'reason',
        cellRenderer: ({ value }) => {
          if (value !== 'incident') {
            return value
          }
          return `<a href="/incidents" data-turbolinks="false" class="cursor-pointer underline">${value}</a>`
        }
      },
      {
        headerName: 'Incident',
        field: 'incident_id',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Notes',
        field: 'notes',
        width: 400,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Actions',
        minWidth: 200,
        filter: false,
        cellRenderer: 'ActionButtons',
        cellRendererParams: {
          onDeleteButtonClick: params => controller.onDeleteExcludePeriodButtonClick(params)
        }
      }
    ],
    components: {
      ActionButtons
    },
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true,
      minWidth: 125
    },
    pagination: true,
    paginationPageSize: 5,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
