import { toPercentString } from '../../../helpers/convert'
import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'

export const bidGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        filter: 'agNumberColumnFilter',
        width: 125,
        pinned: true
      },
      {
        headerName: 'Name',
        field: 'hubscale_name',
        cellRenderer: ({ data, value }) => {
          return `<a href="/networks/${data.id}" data-turbolinks="false" class="cursor-pointer underline">${value}</a>`
        },
        width: 150,
        minWidth: 150,
        pinned: true
      },
      {
        headerName: 'Average Sent',
        field: 'average_sent',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Today Sent',
        field: 'today_sent',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Sent Diff %',
        field: 'sent_diff_percent',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: params => {
          const percent = params.value
          if (percent >= 50) {
            return 'text-red-500'
          }
          if (percent >= 20) {
            return 'text-yellow-500'
          }
          return 'text-green-500'
        }
      },
      {
        headerName: 'Today Rejected',
        field: 'today_rejected',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Rejected %',
        field: 'rejected_percent',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: params => {
          const percent = params.value
          if (percent >= 30) {
            return 'text-red-500'
          }
          if (percent >= 10) {
            return 'text-yellow-500'
          }
          return 'text-green-500'
        }
      },
      {
        headerName: 'Average Imported',
        field: 'average_imported',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Today Imported',
        field: 'today_imported',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Imported Diff %',
        field: 'imported_diff_percent',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: params => {
          const percent = params.value
          if (percent >= 50) {
            return 'text-red-500'
          }
          if (percent >= 20) {
            return 'text-yellow-500'
          }
          return 'text-green-500'
        }
      },
      {
        headerName: 'Today Succeeded Changes',
        field: 'today_succeeded_changes',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Succeeded Changes %',
        field: 'succeeded_changes_percent',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        cellClass: params => {
          const percent = params.value
          if (percent) {
            if (percent < 70) {
              return 'text-red-500'
            }
            if (percent < 90) {
              return 'text-yellow-500'
            }
          }
          return 'text-green-500'
        }
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      filter: true,
      minWidth: 125,
      autoHeight: true
    },
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled
    },
    components: { CheckboxRenderer },
    animateRows: true,
    editType: 'fullRow',
    pagination: true,
    paginationPageSize: 100,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
