import { toBooleanString } from '../../../helpers/convert'
import { valueExists } from '../../../helpers/check'

const existClass = (value) => {
  if (!valueExists(value)) {
    return 'text-yellow-500'
  }
  return value ? 'text-green-500' : 'text-red-500'
}

export const sentDetailGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        pinned: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Date',
        field: 'date',
        pinned: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Sent File?',
        field: 'sent_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Sent Bids',
        field: 'sent_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Default Bids',
        field: 'sent_default_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Campaign Bids',
        field: 'sent_campaign_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Source Bids',
        field: 'sent_source_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Android Bids',
        field: 'sent_android_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'iOS Bids',
        field: 'sent_ios_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'US Bids',
        field: 'sent_us_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'NonUS Bids',
        field: 'sent_non_us_bids',
        filter: 'agNumberColumnFilter'
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      filter: true,
      minWidth: 125,
      width: 150,
      autoHeight: true
    },
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
