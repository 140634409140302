import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { bidChangesGridOptions } from './show/bid_changes_grid_options'
import { getJson } from '../../helpers/request'

export default class extends Controller {
  static targets = [
    'bidChangesGrid',
    'modalPlaceholder',
    'loadingSpinner'
  ]

  static values = {
    bidChangesPath: String,
    fetchBidStatusPath: String
  }

  async connect () {
    useDataGrid(this)
    this.grid = await this.newGrid(this.bidChangesGridTarget, bidChangesGridOptions(this), this.bidChangesPathValue)
  }

  onFetchBidStatusButtonClick = async () => {
    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      const data = await getJson(this.fetchBidStatusPathValue)
      this.grid.setRowData(data)
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }
}
