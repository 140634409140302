export function CheckboxRenderer () {}

CheckboxRenderer.prototype.init = function (params) {
  this.params = params

  this.eGui = document.createElement('input')
  this.eGui.type = 'checkbox'
  this.eGui.checked = params.value

  this.checkedHandler = this.checkedHandler.bind(this)
  this.eGui.addEventListener('click', this.checkedHandler)
}

CheckboxRenderer.prototype.checkedHandler = function (e) {
  const checked = e.target.checked
  const colId = this.params.column.colId
  this.params.node.setDataValue(colId, checked)
}

CheckboxRenderer.prototype.getGui = function (params) {
  return this.eGui
}

CheckboxRenderer.prototype.destroy = function (params) {
  this.eGui.removeEventListener('click', this.checkedHandler)
}
