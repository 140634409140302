export const valueExists = value => {
  return typeof value !== 'undefined' && value !== null
}

export const compareDates = (filterDate, cellValue) => {
  if (!valueExists(cellValue)) {
    return 0
  }

  const cellDate = Date.parse(cellValue)

  if (cellDate < filterDate) {
    return -1
  }

  if (cellDate > filterDate) {
    return 1
  }

  return 0
}

export const isToday = (value) => {
  const today = new Date()
  const someDate = new Date(value)
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}
