import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { gridOptions } from './index/grid_options'

export default class extends Controller {
  static targets = [
    'grid',
    'modalPlaceholder'
  ]

  static values = {
    getBidBatchesPath: String
  }

  connect () {
    useDataGrid(this)
    this.newGrid(this.gridTarget, gridOptions(this), this.getBidBatchesPathValue)
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }
}
