import { addLoadingSpinner, removeLoadingSpinner } from './loading'
import { handleDisabledButtonState } from './button_states'
import { createNotification, triggerNotification } from './notifications'

export const getJson = path => {
  const request = { method: 'GET' }
  return doFetch(path, request, null)
}

export const postJson = (path, json, triggeringButtonElement) => {
  const request = createJsonRequest('POST', json)
  return doFetch(path, request, triggeringButtonElement)
}

export const putJson = (path, json, triggeringButtonElement) => {
  const request = createJsonRequest('PUT', json)
  return doFetch(path, request, triggeringButtonElement)
}

export const deleteJson = (path, json, triggeringButtonElement) => {
  const request = createJsonRequest('DELETE', json)
  return doFetch(path, request, triggeringButtonElement)
}

const createJsonRequest = (method, data) => ({
  method,
  redirect: 'follow',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
  },
  body: JSON.stringify(data)
})

const doFetch = (path, request, triggeringButtonElement) => {
  if (triggeringButtonElement) {
    handleDisabledButtonState(triggeringButtonElement, true)
    addLoadingSpinner(triggeringButtonElement)
  }

  return fetch(path, request)
    .then(res => {
      if (res.status >= 500) throw Error(res.statusText)
      return res
    })
    .catch(error => {
      if (triggeringButtonElement) {
        handleDisabledButtonState(triggeringButtonElement, false)
        removeLoadingSpinner()
      }

      const notification = createNotification('Uh-oh, something wrong happened!', error.message)
      triggerNotification(notification)

      throw error
    }
    )
    .then(res => res.json().then(json => ({ res, json })))
    .then(({ res, json }) => {
      const { data, notification, redirect_url } = json

      if (triggeringButtonElement) {
        handleDisabledButtonState(triggeringButtonElement, false)
        removeLoadingSpinner()
      }

      if (notification && !redirect_url) triggerNotification(notification)
      if (res.status >= 400) throw Error(res.statusText)
      if (notification && redirect_url) setTimeout(() => triggerNotification(notification), 1000)
      if (redirect_url) Turbolinks.visit(redirect_url)

      return data
    })
}
