export const bidChangesGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Ad Network',
        field: 'ad_network'
      },
      {
        headerName: 'Campaign ID',
        field: 'campaign_id',
        width: 500,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Campaign Name',
        field: 'campaign_name',
        width: 300,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Country',
        field: 'country'
      },
      {
        headerName: 'Site ID',
        field: 'site_id',
        width: 500,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Old Value',
        field: 'old_value'
      },
      {
        headerName: 'New Value',
        field: 'change_value'
      },
      {
        headerName: 'Default Bid',
        field: 'default_bid',
        cellRenderer: ({ value }) => parseFloat(value).toFixed(4)
      },
      {
        headerName: 'Status',
        field: 'status',
        cellClassRules: {
          'text-green-500': params => params.value === 'Done',
          'text-red-500': params => params.value !== 'Done'
        }
      },
      {
        headerName: 'Error Message',
        field: 'error_message',
        width: 300,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true,
      minWidth: 125,
      enableCellTextSelection: true
    },
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
