import { getJson, putJson } from '../../helpers/request'
import { Grid } from 'ag-grid-community'
import { popModal } from '../../helpers/modal'

export const useDataGrid = controller => {
  Object.assign(controller, {
    gridApisByTarget: new Map(),
    async newGrid (target, gridOptions, getDataPath) {
      gridOptions.onGridReady = ev => ev.api.sizeColumnsToFit()
      gridOptions.onRowEditingStarted = ev => this.savePreviousRowValue(ev)

      new Grid(target, gridOptions)
      const { api } = gridOptions
      this.gridApisByTarget.set(target, api)

      if (getDataPath) {
        api.showLoadingOverlay()
        const data = await getJson(getDataPath)
        api.setRowData(data)
        api.redrawRows()
      }

      api.hideOverlay()

      return api
    },
    async updateRecord (ev, path, payload) {
      // /!\ poor man's deep equal
      if (JSON.stringify(ev.data) !== JSON.stringify(ev.node.previousRowValue)) {
        ev.api.showLoadingOverlay()
        try {
          const data = payload || ev.data
          await putJson(path, data, null)

          const ret = { ...payload, ...ev.data }
          ev.node.setData(ret)
        } catch (error) {
          console.log(error)
          ev.node.setData(ev.node.previousRowValue)
        } finally {
          ev.api.hideOverlay()
        }
      }
    },
    safeCheckbox (params, path, modalPlaceholder, modalTitle, actionLabel, description = 'Are you sure?') {
      const { id } = params.data

      const oldData = params.data
      oldData[params.colDef.field] = params.oldValue
      params.node.setData(oldData)

      const action = async ({ target }, destroyModal) => {
        try {
          await putJson(path, { id, [params.colDef.field]: params.newValue }, target)
          params.data[params.colDef.field] = params.newValue
          params.node.setData(params.data)
        } catch (error) {
          console.log()
        } finally {
          destroyModal()
        }
      }

      popModal(
        modalPlaceholder,
        {
          title: modalTitle,
          description,
          color: 'green',
          actionButtonLabel: actionLabel
        },
        action
      )
    },
    savePreviousRowValue (ev) {
      ev.node.previousRowValue = $.extend(true, {}, ev.data)
    },
    destroyAllGrids () {
      this.gridApisByTarget.forEach(api => api.destroy())
      this.gridApisByTarget.clear()
    }
  })
}
