import {Controller} from "stimulus";
import {Chart} from "chart.js";

export default class extends Controller {

    chart: Chart

    connect() {
        const context = (this.element as HTMLCanvasElement).getContext('2d')
        this.chart = new Chart(context, {
            type: 'line',
            data: undefined,
            plugins: [],
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: this.yAxisTitle,
                        },
                    },
                },
                animation: false,
            },
        })
    }

    get yAxisTitle() {
        return this.data.get('y-axis-title')
    }

    onTurbolinksBeforeCache() {
        this.chart.destroy()
    }
}