export const handleDisabledButtonState = (target, shouldBeDisabled) => {
  target.disabled = shouldBeDisabled
  shouldBeDisabled ? target.classList.add('opacity-50') : target.classList.remove('opacity-50')
}

export const handleFormDisabledButtonState = (formElement, buttonElement) => {
  const hasEmptyFields = Array.from(formElement.elements)
    .filter(e => e.type !== 'hidden' && e.type !== 'submit' && !e.disabled)
    .some(e => e.value === '')

  handleDisabledButtonState(buttonElement, hasEmptyFields)
}
