import palette from 'google-palette'
import { Chart } from 'chart.js'

const createTimePeriodAnnotations = referenceRow => {
  const mapped = referenceRow.map(x => x.time_period)
  const lines = {}

  const control = referenceRow[mapped.indexOf('before')]
  if (control) {
    lines.control = {
      type: 'line',
      xMin: control.install_date,
      xMax: control.install_date,
      borderColor: 'orange',
      borderWidth: 1,
      label: {
        enabled: true,
        position: 'end',
        content: 'control'
      }
    }
  }

  const before = referenceRow[mapped.lastIndexOf('before')]
  if (before) {
    lines.before = {
      type: 'line',
      xMin: before.install_date,
      xMax: before.install_date,
      borderColor: 'red',
      borderWidth: 1,
      label: {
        enabled: true,
        position: 'end',
        content: 'before'
      }
    }
  }

  const after = referenceRow[mapped.findIndex(x => x === 'after')]
  if (after) {
    lines.after = {
      type: 'line',
      xMin: after.install_date,
      xMax: after.install_date,
      borderColor: 'green',
      borderWidth: 1,
      label: {
        enabled: true,
        position: 'end',
        content: 'after'
      }
    }
  }

  return lines
}

const createExcludePeriodAnnotations = (excludePeriods, lowestYAxisPoint, highestYAxisPoint) => {
  const boxColor = 'rgba(0, 0, 0, 0.2)'
  const labelColor = 'rgba(0, 0, 0, 0.5)'
  const items = {}

  excludePeriods = [...excludePeriods.sort((a, b) => new Date(a.from_date) - new Date(b.from_date))]
  excludePeriods.forEach((period, i) => {
    const { from_date, to_date } = period
    const labelY = (i % 2 === 0) ? highestYAxisPoint : lowestYAxisPoint

    items['period-' + i + '-box'] = {
      type: 'box',
      xMin: from_date,
      xMax: to_date,
      yMin: lowestYAxisPoint,
      yMax: highestYAxisPoint,
      borderColor: boxColor,
      backgroundColor: boxColor
    }

    items['period-' + i + '-label'] = {
      type: 'line',
      borderColor: 'transparent',
      xMin: from_date,
      xMax: to_date,
      yMin: labelY,
      yMax: labelY,
      label: {
        backgroundColor: labelColor,
        enabled: true,
        position: 'center',
        content: `Exclude #${period.id}`
      }
    }
  })

  return items
}

const resetChartsData = (chartDefinitions, controllerTargetMarker) => {
  Object
    .values(Chart.instances)
    .filter(f =>
      chartDefinitions.includes(({ placeholder }) => f.canvas.dataset[controllerTargetMarker] === placeholder)
    )
    .forEach(chart => {
      chart.data.datasets = []
      chart.data.labels = []
      chart.options.plugins.annotation = {}
      chart.update()
    })
}

export const updateCharts = async (controllerTargetMarker, chartDefinitions, fetchExcludePeriods, fetchResults) => {
  resetChartsData(chartDefinitions, controllerTargetMarker)

  const [excludePeriods, results] = await Promise.all([fetchExcludePeriods(), fetchResults()])

  const groupIds = Object.keys(results)
  const colors = palette('tol', groupIds.length).map(hex => '#' + hex)
  const referenceRow = Object.values(results)[0]
  const timePeriodAnnotations = createTimePeriodAnnotations(referenceRow)

  chartDefinitions.forEach(({ placeholder, mapper }) => {
    const datasets = groupIds.map((groupId, i) => ({
      label: groupId,
      data: mapper(results[groupId]),
      backgroundColor: colors[i],
      color: colors[i],
      borderColor: colors[i],
      fill: false
    })
    )

    const lowestYAxisPoint = Math.min(...datasets.flatMap(dataset => dataset.data.map(o => o.y)))
    const highestYAxisPoint = Math.max(...datasets.flatMap(dataset => dataset.data.map(o => o.y)))

    const chart = Object.values(Chart.instances).find(o =>
      o.canvas.dataset[controllerTargetMarker] === placeholder.dataset[controllerTargetMarker]
    )

    const excludePeriodAnnotations = createExcludePeriodAnnotations(excludePeriods, lowestYAxisPoint, highestYAxisPoint)

    chart.data.datasets = datasets
    chart.data.labels = datasets[0].data.map(o => o.x)
    chart.options.plugins = { annotation: { annotations: { ...excludePeriodAnnotations, ...timePeriodAnnotations } } }
    chart.update()
  })
}
