import { toPercentString } from '../../../helpers/convert'

export const summaryGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Version',
        field: 'autobid_version',
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Group',
        field: 'group_name',
        valueGetter: params => ['A', 'B', 'Overall'][params.node.rowIndex],
        hide: controller.type !== 'switchback'
      },
      {
        headerName: 'MO %',
        field: 'margin_objective',
        cellRenderer: ({ value }) => toPercentString(value),
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Total Games',
        field: 'total_games'
      },
      {
        headerName: 'Android Games',
        field: 'android_games'
      },
      {
        headerName: 'iOS Games',
        field: 'ios_games'
      },
      {
        headerName: 'Predicted Margin',
        field: 'total_margin'
      },
      {
        headerName: 'Calendar Margin',
        field: 'calendar_margin'
      },
      {
        headerName: 'Total Spends',
        field: 'total_spends'
      },
      {
        headerName: 'Total Revenues',
        field: 'total_revenues'
      },
      {
        headerName: 'Total Installs',
        field: 'total_installs'
      },
      {
        headerName: 'Organic Installs',
        field: 'organic_installs'
      },
      {
        headerName: 'Android Margin',
        field: 'android_margin'
      },
      {
        headerName: 'iOS Margin',
        field: 'ios_margin'
      },
      {
        headerName: 'Android Spends',
        field: 'android_spends'
      },
      {
        headerName: 'iOS Spends',
        field: 'ios_spends'
      },
      {
        headerName: 'Android Installs',
        field: 'android_installs'
      },
      {
        headerName: 'iOS Installs',
        field: 'ios_installs'
      },
      {
        headerName: 'US Margin',
        field: 'us_margin'
      },
      {
        headerName: 'Non-US Margin',
        field: 'non_us_margin'
      },
      {
        headerName: 'US Spends',
        field: 'us_spends'
      },
      {
        headerName: 'Non-US Spends',
        field: 'non_us_spends'
      },
      {
        headerName: 'US Installs',
        field: 'us_installs'
      },
      {
        headerName: 'Non-US Installs',
        field: 'non_us_installs'
      }
    ],
    defaultColDef: {
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 150,
      minWidth: 150,
      resizable: true
    },
    rowClassRules: {
      'border-yellow-300 bg-yellow-50': params => params.data.is_control_group
    },
    animateRows: true,
    suppressRowClickSelection: true,
    pagination: false,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
