import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { sentSummaryGridOptions } from './bids_report/sent_summary_grid_options'
import { sentDetailGridOptions } from './bids_report/sent_detail_grid_options'
import { importedGridOptions } from './bids_report/imported_grid_options'
import { budgetGridOptions } from './bids_report/budget_grid_options'
import { getJson } from '../../helpers/request'

export default class extends Controller {
  static targets = ['sentSummaryGrid', 'sentDetailGrid', 'importedGrid', 'budgetGrid', 'loadingSpinner']
  static values = {
    getNetworkBidsReportPath: String,
    getNetworkBudgetsReportPath: String
  }

  async connect () {
    useDataGrid(this)
    await this.initializeBidsReportGrids()
    await this.fetchBidsReport()
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  initializeBidsReportGrids = async () => {
    this.grids = await Promise.all(
      [
        [this.sentSummaryGridTarget, sentSummaryGridOptions],
        [this.sentDetailGridTarget, sentDetailGridOptions],
        [this.importedGridTarget, importedGridOptions]
      ].map(([target, options]) => {
        return this.newGrid(target, options(this))
      })
    )
    this.budgetGrid = await this.newGrid(this.budgetGridTarget, budgetGridOptions(this))
  }

  fetchBidsReport = async () => {
    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      await this.updateDataTables()
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }

  updateDataTables = async () => {
    this.grids.forEach(grid => grid.showLoadingOverlay())
    this.budgetGrid.showLoadingOverlay()

    try {
      const data = await Promise.all([
        getJson(this.getNetworkBidsReportPathValue),
        getJson(this.getNetworkBudgetsReportPathValue)
      ])

      this.grids.forEach(grid => grid.setRowData(data[0]))
      this.budgetGrid.setRowData(data[1])
    } catch {
      this.grids.forEach(grid => grid.hideOverlay())
      this.budgetGrid.hideLoadingOverlay()
    }
  }
}
