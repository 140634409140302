import { toBooleanString } from '../../../helpers/convert'
import { valueExists } from '../../../helpers/check'

const existClass = (value) => {
  if (!valueExists(value)) {
    return 'text-yellow-500'
  }
  return value ? 'text-green-500' : 'text-red-500'
}

export const importedGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        pinned: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Date',
        field: 'date',
        pinned: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Imported Source File?',
        field: 'imported_source_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Imported Source Bids',
        field: 'imported_source_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Imported Campaign File?',
        field: 'imported_campaign_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Imported Campaign Bids',
        field: 'imported_campaign_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Imported Changed File?',
        field: 'imported_changed_file_exists',
        cellRenderer: ({ value }) => toBooleanString(value),
        cellClass: ({ value }) => existClass(value)
      },
      {
        headerName: 'Imported Changed Bids',
        field: 'imported_changed_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Failed Bid Changes',
        field: 'failed_bid_changes',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Succeeded Bid Changes',
        field: 'succeeded_bid_changes',
        filter: 'agNumberColumnFilter'
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      filter: true,
      minWidth: 125,
      width: 150,
      autoHeight: true
    },
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
