import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { getJson, postJson } from '../../helpers/request'
import { handleDisabledButtonState } from '../../helpers/button_states'
import { getSelectValue } from '../../helpers/select'
import { gameSelectionOptions } from './new/game_selection_options'
import { splitGridOptions } from './new/split_grid_options'
import { summaryGridOptions } from './new/summary_grid_options'
import { versionsGridOptions } from './new/versions_grid_options'

export default class extends Controller {
  static targets = [
    'gamesSelectionGrid',
    'groupsDynamicList',
    'versionsList',
    'gameIdsInput',
    'step2NextButton',
    'saveButton',
    'typesSelect',
    'periodCountSelect',
    'periodDurationInput',
    'splitStrategySelect',
    'marginDataTypeSelect',
    'controlGroupVersionSelect',
    'versionASelect',
    'versionBSelect',
    'purpose',
    'splits',
    'summaryGrid',
    'versionsGrid',
    'allocationsGrid'
  ]

  static values = {
    availableGamesPath: String,
    computeSplitPath: String,
    saveExperimentPath: String,
    enabledAutobidVersionsPath: String
  }

  async connect () {
    useDataGrid(this)

    const data = await getJson(this.enabledAutobidVersionsPathValue)
    this.versionsCache = data

    this.populateVersions()

    this.newGrid(this.gamesSelectionGridTarget, gameSelectionOptions(this.updateStep2NextButtonState))
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  populateVersions = ev => {
    ;['controlGroupVersionSelectTarget', 'versionASelectTarget', 'versionBSelectTarget'].forEach((target) => {
      this.versionsCache
        .map(this.buildVersionOption)
        .forEach(v => $(this[target]).append(v))
    })
  }

  buildVersionOption (version) {
    const optionText = `v${version.version}: ${version.info}`
    const option = new Option(optionText, version.id, false, false)
    option.setAttribute('data-version-id', version.id)
    return option
  }

  fetchAvailableGames = async ev => {
    const type = getSelectValue(this.typesSelectTarget)
    const marginDataType = 'ilrd'

    if (type && marginDataType) {
      const api = this.gridApisByTarget.get(this.gamesSelectionGridTarget)
      api.showLoadingOverlay()
      const data = await getJson(this.availableGamesPath({
        type,
        margin_data_type: marginDataType
      }))
      api.setRowData(data)
    }
  }

  availableGamesPath (queryParams) {
    return this.availableGamesPathValue + '?' + new URLSearchParams(queryParams)
  }

  addGroup = ev => {
    const placeholder = this.groupsDynamicListTarget

    const gridDiv = document.createElement('div')
    gridDiv.className = 'mt-2 sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start'

    const label = document.createElement('label')
    label.className = 'block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
    label.innerText = `Group ${Array.from(placeholder.childNodes).length + 1}`
    gridDiv.appendChild(label)

    const fieldsDiv = document.createElement('div')
    fieldsDiv.className = 'flex sm:mt-0 sm:col-span-4'
    gridDiv.appendChild(fieldsDiv)

    const dropdown = document.createElement('select')
    dropdown.className =
      'mt-2 w-3/4 rounded focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 sm:text-sm'
    ;[new Option('Select a version', '', true, false)]
      .concat(
        this.versionsCache.map(this.buildVersionOption)
      )
      .forEach(v => $(dropdown).append(v))
    $(dropdown).on('change', this.updateStep2NextButtonState)
    fieldsDiv.appendChild(dropdown)

    const input = document.createElement('input')
    input.type = 'number'
    input.className =
      'mt-2 ml-2 w-1/4 rounded-l-md block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300'
    input.placeholder = 'Margin objective'
    fieldsDiv.appendChild(input)

    const span = document.createElement('span')
    span.className =
      'mt-2 inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm'
    span.textContent = '%'
    fieldsDiv.appendChild(span)

    const buttonsDiv = document.createElement('div')
    gridDiv.appendChild(buttonsDiv)

    const addButton = document.createElement('button')
    addButton.className =
      'mt-2 bg-green-500 hover:bg-green-400 text-white font-bold py-1 px-8 border-b-4 border-green-700 hover:green-blue-500 focus:outline-none focus:shadow-outline-green focus:border-green-600 active:bg-green-600 transition duration-150 ease-in-out rounded'
    addButton.dataset.action = 'experiments--new#addGroup'
    addButton.innerText = '+'
    buttonsDiv.appendChild(addButton)

    const removeButton = document.createElement('button')
    removeButton.className =
      'mt-2 ml-1 bg-red-500 hover:bg-red-400 text-white font-bold py-1 px-8 border-b-4 border-red-700 hover:red-blue-500 focus:outline-none focus:shadow-outline-red focus:border-red-600 active:bg-red-600 transition duration-150 ease-in-out rounded'
    removeButton.dataset.action = 'experiments--new#removeGroup'
    removeButton.innerText = '-'
    buttonsDiv.appendChild(removeButton)

    $(placeholder.lastChild).children('div').first().next().children('button').first().remove()
    $(placeholder.lastChild).children('div').first().next().children('button').first().remove()

    placeholder.appendChild(gridDiv)
    this.enableOrDisableGroupMO()
  }

  removeGroup = ev => {
    const placeholder = this.groupsDynamicListTarget
    const groupNb = Array.from(placeholder.childNodes).length

    const isLastChild = !ev.target.parentElement.parentElement.nextElementSibling
    if (groupNb > 1) {
      const div = ev.target.parentElement.parentElement
      $(div)
        .children('div')
        .children('select')
        .first()
        .off('change', this.updateStep2NextButtonState)
      div.remove()
    }

    if (isLastChild) {
      const dynamicListHasMoreThan1Group = Array.from(placeholder.childNodes).length > 1
      const addButton = document.createElement('button')
      addButton.className =
        'bg-green-500 hover:bg-green-400 text-white font-bold py-1 px-8 border-b-4 border-green-700 hover:green-blue-500 focus:outline-none focus:shadow-outline-green focus:border-green-600 active:bg-green-600 transition duration-150 ease-in-out rounded'
      if (dynamicListHasMoreThan1Group) addButton.classList.add('mt-2')
      addButton.dataset.action = 'experiments--new#addGroup'
      addButton.innerText = '+'

      if (dynamicListHasMoreThan1Group) {
        const removeButton = document.createElement('button')
        removeButton.className =
          'ml-1 bg-red-500 hover:bg-red-400 text-white font-bold py-1 px-8 border-b-4 border-red-700 hover:red-blue-500 focus:outline-none focus:shadow-outline-red focus:border-red-600 active:bg-red-600 transition duration-150 ease-in-out rounded'
        if (dynamicListHasMoreThan1Group) removeButton.classList.add('mt-2')
        removeButton.dataset.action = 'experiments--new#removeGroup'
        removeButton.innerText = '-'

        $(placeholder.lastChild)
          .children('div')
          .first()
          .next()
          .children('button')
          .first()
          .addClass('ml-1')
        $(placeholder.lastChild).children('div').first().next().prepend(removeButton)
      }

      $(placeholder.lastChild).children('div').first().next().prepend(addButton)
      this.updateStep2NextButtonState()
    }
  }

  onGameIdsInputChange = ev => {
    const api = this.gridApisByTarget.get(this.gamesSelectionGridTarget)
    api.deselectAll()
    ev.target.value.split(',').forEach(id => {
      const nodeId = parseInt(id)
      if (!isNaN(nodeId)) {
        const node = api.getRowNode(nodeId)
        if (node) node.setSelected(true)
      }
    })
  }

  onTypeChange = ev => {
    this.enableOrDisableGroupMO()

    const periodSettings = document.getElementById('period-settings')
    const groupSettings = document.getElementById('group-settings')
    const versionSettings = document.getElementById('version-settings')
    const evenHint = document.getElementById('even-hint')
    const splitAgain = document.getElementById('split-again')
    const allocationResults = document.getElementById('allocation-results')

    if (this.type === 'switchback') {
      periodSettings.classList.remove('hidden')
      versionSettings.classList.remove('hidden')
      evenHint.classList.remove('hidden')
      groupSettings.classList.add('hidden')
      splitAgain.classList.add('hidden')
      allocationResults.classList.remove('hidden')
    } else {
      periodSettings.classList.add('hidden')
      versionSettings.classList.add('hidden')
      evenHint.classList.add('hidden')
      groupSettings.classList.remove('hidden')
      splitAgain.classList.remove('hidden')
      allocationResults.classList.add('hidden')
    }
  }

  createSplitGrids = async nbGrids => {
    if (this.type === 'switchback') {
      return
    }

    this.splitsTarget.innerHTML = ''
    return await Promise.all(
      Array(nbGrids)
        .fill()
        .map(async (_, index) => {
          const groupName = this.type === 'switchback' ? ['A', 'B'][index] : index + 1

          this.splitsTarget.insertAdjacentHTML(
            'beforeend',
            `
                <div>
                    <h3 id="split-${index}-title" class="text-2xl text-center font-semibold">Group ${groupName}</h3>
                    <h4 id="split-${index}-detail" class="text-center text-sm text-gray-900">-</h4>
                    <div id="split-${index}-grid" class="mt-2 ag-theme-alpine" style="height: 500px; width:100%;"></div>
                </div>
            `
          )

          const gridPlaceholder = document.getElementById(`split-${index}-grid`)
          const api = await this.newGrid(gridPlaceholder, splitGridOptions(this))
          api.showLoadingOverlay()
          return api
        })
    )
  }

  createSummaryGrid = async () => {
    if (this.type === 'switchback') {
      return
    }

    this.summaryGridTarget.innerHTML = ''
    const api = await this.newGrid(this.summaryGridTarget, summaryGridOptions(this))
    api.showLoadingOverlay()
    return api
  }

  createVersionsGrid = async () => {
    if (this.type !== 'switchback') {
      return
    }

    this.versionsGridTarget.innerHTML = ''
    const api = await this.newGrid(this.versionsGridTarget, versionsGridOptions(this))
    api.showLoadingOverlay()
    return api
  }

  createAllocationsGrid = async () => {
    if (this.type !== 'switchback') {
      return
    }

    this.allocationsGridTarget.innerHTML = ''
    const api = await this.newGrid(this.allocationsGridTarget, splitGridOptions(this))
    api.showLoadingOverlay()
    return api
  }

  computeSplit = async ev => {
    const definedGroups = this.experimentGroups

    const grids = await this.createSplitGrids(definedGroups.length)
    const summaryGrid = await this.createSummaryGrid()
    const versionsGrid = await this.createVersionsGrid()
    const allocationsGrid = await this.createAllocationsGrid()

    try {
      const periodDuration = this.type === 'switchback' ? this.periodDurationInputTarget.value : null
      const periodsCount = this.type === 'switchback' ? getSelectValue(this.periodCountSelectTarget) : null

      const payload = {
        type: getSelectValue(this.typesSelectTarget),
        purpose: this.purposeTarget.value,
        split_strategy: 'app',
        margin_data_type: 'ilrd',
        groups: definedGroups,
        game_ids: this.selectedGameIds,
        period_duration: periodDuration,
        periods_count: periodsCount
      }

      const {
        uuid,
        groups,
        total_games,
        android_games,
        ios_games,
        discrepancies,
        worst_discrepancy
      } = await postJson(this.computeSplitPathValue, payload, null)

      this.computeSplitUuid = uuid

      if (this.type === 'switchback') {
        const versionsData = [...groups].map(group => {
          const { autobid_version, margin_objective, is_control_group } = group

          return { autobid_version, margin_objective, is_control_group }
        })

        versionsGrid.setRowData(versionsData)

        const allocationsData = [...groups]
          .map(group => group.selected_games)
          .flat()
          .sort((a, b) => a.pair_id - b.pair_id)

        allocationsGrid.setRowData(allocationsData)
      } else {
        const summaryData = [...groups]
        const overall_row = {
          autobid_version: 'Overall',
          total_games,
          android_games,
          ios_games
        }
        for (const [key, value] of Object.entries(discrepancies)) {
          overall_row[key] = `${value}%`
        }

        summaryData.push(overall_row)
        summaryGrid.setRowData(summaryData)

        const worstMetric = worst_discrepancy[0]
        const worstDiscrepancy = worst_discrepancy[1]
        document.getElementById(
          'summary-detail'
        ).innerText = `Worst discrepancy: ${worstDiscrepancy}% on ${worstMetric}`

        groups.forEach(
          ({ selected_games, autobid_version, is_control_group, margin_objective }, index) => {
            let detail = is_control_group ? 'Control group:' : ''
            detail += ` Version ${autobid_version}`
            detail += ` + MO ${margin_objective ? margin_objective + '%' : 'not specified'}`
            document.getElementById(`split-${index}-detail`).innerText = detail

            grids[index].setRowData(selected_games)
          }
        )
      }
    } catch (error) {
      console.log(error)
      if (this.type === 'switchback') {
        versionsGrid.hideOverlay()
        allocationsGrid.hideOverlay()
      } else {
        grids.concat(summaryGrid).forEach(grid => grid.hideOverlay())
      }
    }
  }

  saveExperiment = async () => {
    try {
      const payload = { uuid: this.computeSplitUuid }
      await postJson(this.saveExperimentPathValue, payload, this.saveButtonTarget)
    } catch (error) {
      console.log(error)
    }
  }

  enableOrDisableGroupMO = () => {
    const value = getSelectValue(this.typesSelectTarget)
    const nodes = this.groupsDynamicListTarget.querySelectorAll('input')
    if (value === 'release') {
      nodes.forEach(n => {
        n.value = ''
        n.setAttribute('disabled', true)
      })
    } else { nodes.forEach(n => n.removeAttribute('disabled')) }
  }

  updateStep2NextButtonState = () => {
    const shouldBeDisabled = !(
      this.selectedGameIds.length >= this.experimentGroups.length &&
      this.experimentGroups.length >= 2 &&
      (this.type !== 'switchback' || this.selectedGameIds.length % 2 === 0)
    )

    handleDisabledButtonState(this.step2NextButtonTarget, shouldBeDisabled)
  }

  get type () {
    return this.typesSelectTarget.value
  }

  get periodsCount () {
    return this.type === 'switchback' ? getSelectValue(this.periodCountSelectTarget) : null
  }

  get selectedGameIds () {
    return this.gridApisByTarget
      .get(this.gamesSelectionGridTarget)
      .getSelectedNodes()
      .map(n => n.data.id)
  }

  get experimentGroups () {
    const target = this.type === 'switchback' ? this.versionsListTarget : this.groupsDynamicListTarget

    const raw = Array.from(target.childNodes).map(n => {
      const fieldsDiv = $(n).children('div')
      const select = $(fieldsDiv).children('select')
      const isControlGroup = ['controlGroupVersionSelect', 'versionASelect'].includes(select.first().attr('name'))
      const autobidVersionId = select.find(':selected').data('versionId')
      if (autobidVersionId === undefined) return undefined

      const result = { id: autobidVersionId, is_control_group: isControlGroup }
      const marginObjectiveInput = $(fieldsDiv).children('input').first().val()
      const marginObjective =
        marginObjectiveInput === '' ? undefined : parseFloat(marginObjectiveInput)
      return { ...result, margin_objective: marginObjective }
    })

    return raw.filter(v => v !== undefined)
  }
}
