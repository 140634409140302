import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { getSelectValue } from '../../helpers/select'
import { gamesGridOptions } from './show/games_grid_options'
import { getJson } from '../../helpers/request'
import { marginsGamesGridOptions } from './show/margins_games_grid_options'
import { spendsGamesGridOptions } from './show/spend_games_grid_options'
import { installsGamesGridOptions } from './show/installs_games_grid_options'
import { organicInstallsGamesGridOptions } from './show/organic_installs_games_grid_options'
import { calendarMarginsGamesGridOptions } from './show/calendar_margins_games_grid_options'
import { updateCharts } from './show/chart_logic'

export default class extends Controller {
  static targets = [
    // Grids
    'experimentGamesGrid',
    'marginsGamesGrid',
    'calendarMarginsGamesGrid',
    'spendsGamesGrid',
    'installsGamesGrid',
    'organicInstallsGamesGrid',
    // Charts
    'spendsGamesChart',
    'installsGamesChart',
    'organicInstallsGamesChart',
    'marginsGamesChart',
    'calendarMarginsGamesChart',
    // Dropdowns
    'gameLevelCountryGroupSelect',
    'gameLevelModelWaitDaysSelect',
    // Misc
    'modalPlaceholder',
    'loadingSpinner'
  ]

  static values = {
    experimentGamesPath: String,
    experimentDayToDayResultsPath: String,
    updateExperimentSplitPath: String,
    experimentExcludePeriodsPath: String,
    experimentType: String
  }

  async connect () {
    useDataGrid(this)

    this.grids = await this.initializeGameLevelResultGrids()

    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      await this.updateGameLevelDatatables()
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  get type () {
    return this.experimentTypeValue
  }

  updateGameLevelExperimentResults = async () => {
    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      const selectedRow = this.grids[0].getSelectedRows()[0]
      if (selectedRow) {
        const { app_id, platform } = selectedRow
        await Promise.all([this.updateGameLevelDatatables(), this.updateGameLevelCharts(app_id, platform)])
        this.grids.forEach(g => g.getRowNode(app_id).setSelected(true))
      } else await this.updateGameLevelDatatables()
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }

  updateGameLevelCharts = async (appId, platform) => {
    const fetchExcludePeriods = () => getJson(this.experimentExcludePeriodsPathValue)
    const fetchResults = () => getJson(
      this.experimentDayToDayResultsPath({
        refresh_cache: false,
        app_id: appId,
        platform,
        model_wait_days: getSelectValue(this.gameLevelModelWaitDaysSelectTarget),
        country_group: getSelectValue(this.gameLevelCountryGroupSelectTarget)
      })
    )

    await updateCharts(
      'experiments-ShowGameLevelTarget',
      this.chartDefinitions,
      fetchExcludePeriods,
      fetchResults
    )
  }

  updateGameLevelDatatables = async () => {
    const mutationObserver = new MutationObserver(
      () => this.grids.forEach(grid => grid.sizeColumnsToFit()))
    mutationObserver.observe(this.experimentGamesGridTarget, { attributes: true })

    this.grids.forEach(grid => grid.showLoadingOverlay())

    try {
      const params = {
        country_group: getSelectValue(this.gameLevelCountryGroupSelectTarget),
        model_wait_days: getSelectValue(this.gameLevelModelWaitDaysSelectTarget)
      }
      const data = await getJson(this.experimentGamesPath(params))
      this.grids.forEach(grid => grid.setRowData(data))
    } catch {
      this.grids.forEach(grid => grid.hideOverlay())
    }
  }

  onAppNameClick = async (gameId, platform) => {
    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      this.grids.forEach(g => g.getRowNode(gameId).setSelected(true))
      await this.updateGameLevelCharts(gameId, platform)
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }

  initializeGameLevelResultGrids = async () => {
    return await Promise.all(
      [
        [this.experimentGamesGridTarget, gamesGridOptions],
        [this.marginsGamesGridTarget, marginsGamesGridOptions],
        [this.calendarMarginsGamesGridTarget, calendarMarginsGamesGridOptions],
        [this.spendsGamesGridTarget, spendsGamesGridOptions],
        [this.installsGamesGridTarget, installsGamesGridOptions],
        [this.organicInstallsGamesGridTarget, organicInstallsGamesGridOptions]
      ].map(([target, options]) => this.newGrid(target, options(this)))
    )
  }

  get chartDefinitions () {
    return [
      [this.marginsGamesChartTarget, 'predicted_margin'],
      [this.calendarMarginsGamesChartTarget, 'calendar_margin'],
      [this.spendsGamesChartTarget, 'total_spends'],
      [this.installsGamesChartTarget, 'total_installs'],
      [this.organicInstallsGamesChartTarget, 'organic_installs']
    ].map(([placeholder, attr]) => ({
      placeholder,
      mapper: data => data.map(o => ({ x: o.install_date, y: o[attr] }))
    }))
  }

  experimentDayToDayResultsPath (queryParams) {
    return this.experimentDayToDayResultsPathValue + '?' + new URLSearchParams(queryParams)
  }

  experimentGamesPath (queryParams) {
    return this.experimentGamesPathValue + '?' + new URLSearchParams(queryParams)
  }
}
