import {Controller} from "stimulus"
import {handleDisabledButtonState} from '../helpers/button_states'
import { getSelectValues, getSelectValue } from '../helpers/select'
import { putJson, postJson } from '../helpers/request'

export default class extends Controller {
    static values = { savePath: String, method: String }

    savePathValue: String
    methodValue: String
    submitButton = this.element.querySelector('button[type="submit"]')

    connect() {
        this.submitButton.addEventListener('click',  async ev => {
            ev.preventDefault()
            if (this.methodValue === 'put')
                await putJson(this.savePathValue, this.formData, this.submitButton)
            else
                await postJson(this.savePathValue, this.formData, this.submitButton)
        })

        this.element.addEventListener('change', () => this.enableSubmitButtonWhenComplete())
    }

    enableSubmitButtonWhenComplete() {
        const elements = Array.from((this.element as HTMLFormElement).elements)

        const filtered =
            elements
                .filter((e: HTMLFormElement) => {
                    return e.type !== 'hidden' &&
                        e.type !== 'submit' &&
                        !e.disabled &&
                        !e.classList.contains('select2-search__field') &&
                        e.required
                })

        const hasEmptyFields =
            filtered
                .some((e: HTMLFormElement) => {
                    return (e.multiple) ? e.querySelectorAll('option:checked').length === 0 : e.value === ''
                })


        handleDisabledButtonState(this.submitButton, hasEmptyFields)
    }

    get formData() {
        const elements = Array.from(document.querySelectorAll('[name]'))
            .filter((n: HTMLInputElement) => n.nodeName !== 'META' && n.nodeName !== 'BUTTON' && n.type !== 'hidden')

        const regex = new RegExp("\\[(.*?)\\]")
        const data = {}
        for (const element of elements) {
            const { name, type, checked, value, nodeName } = element as any
            const extractValue = () => {
                let ret
                if (nodeName === 'SELECT') {
                    ret = (element as HTMLSelectElement).multiple
                        ? getSelectValues(element)
                        : getSelectValue(element)
                } else {
                    switch (type) {
                        case 'checkbox':
                            ret = checked
                            break
                        default:
                            ret = value
                            break
                    }
                }

                return ret
            }

            if (regex.test(name)) {
                const attrName = name.match(regex)[1]
                data[attrName] = extractValue()
            }
        }

        return data
    }
}
