import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['voodooNetworks', 'id', 'name', 'hubscaleName', 'adjustName']

  fillCommonFields = () => {
    const { id, name, hubscaleName, adjustName } =
        $(this.voodooNetworksTarget).find(':selected').data()

    this.idTarget.value = id
    this.nameTarget.value = name
    this.hubscaleNameTarget.value = hubscaleName
    this.adjustNameTarget.value = adjustName
  }
}
