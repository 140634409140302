import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'
import { toPercentString, toLocaleDateString } from '../../../helpers/convert'
import { compareDates } from '../../../helpers/check'

export const uaGridOptions = (controller, autobidVersions, allUa) => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        pinned: true,
        filter: 'agNumberColumnFilter',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        headerName: 'Name',
        field: 'name',
        pinned: true,
        minWidth: 270,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
        cellRenderer: ({ data, value }) => {
          const admin = window.current_user_attributes.admin
          const link = admin ? `/autobid_games/${data.id}/bidding_rules` : `/autobid_games/${data.id}/history`

          return `
                            <a class="pr-3" href="/autobid_games/${data.id}/history" class="cursor-pointer underline">
                              <i class="fa fa-history"></i>
                            </a>
                            <a href="${link}" data-turbolinks="false" class="cursor-pointer underline">
                            ${value} 
                            </a>
                        `
        }
      },
      {
        headerName: 'Platform',
        field: 'platform',
        pinned: true
      },
      {
        headerName: 'UA Associate',
        field: 'ua_associate',
        cellRenderer: ({ value }) => value ? value.replace(/@.+/, '') : '-',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['None', ...allUa]
        },
        editable: true,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
        minWidth: 150
      },
      {
        headerName: 'Default Version',
        field: 'version',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: autobidVersions.map(v => v.version)
        },
        editable: params => !params.data.is_version_change_restricted
      },
      {
        headerName: 'Roas Target',
        field: 'roas_target',
        editable: true,
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ data, value }) => {
          return `
            <a href="/autobid_games/${
              data.id
            }/bidding_rules" data-turbolinks="false" class="cursor-pointer underline">
            ${toPercentString(value * 100)} 
            </a>
          `
        }
      },
      {
        headerName: 'Roas Window',
        field: 'roas_dx',
        editable: true,
        filter: 'agNumberColumnFilter',
        minWidth: 200
      },
      {
        headerName: 'LTV Correction',
        field: 'ltv_correction',
        editable: true,
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ data, value }) => {
          return `
            <a href="/autobid_games/${
              data.id
            }/ltv_correction_rules" data-turbolinks="false" class="cursor-pointer underline">
            ${toPercentString(value)}
            </a>
          `
        },
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'With LTV Correction?',
        field: 'ltv_correction_presence',
        filter: true,
        cellRenderer: ({ value }) => {
          return value ? '✔' : '✖'
        }
      },
      {
        headerName: 'Experiment',
        field: 'experiment_id',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => {
          const link = `<a href="/experiments/${value}" data-turbolinks="false" class="cursor-pointer underline">${value}</a>`
          return value ? link : '-'
        }
      },
      {
        headerName: 'Experiment Version',
        field: 'experiment_version',
        cellRenderer: ({ value }) => value || '-',
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'Experiment MO %',
        field: 'experiment_margin_objective',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'Enabled?',
        field: 'is_enabled',
        filter: true,
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.gameUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable Game' : 'Disable Game',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: 'No Experiment?',
        field: 'exclude_from_experiment',
        filter: true,
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.gameUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Exclude Game From Experiment' : 'Include Game In Experiment',
            params.newValue ? 'Exclude' : 'Include'
          )
      },
      {
        headerName: 'Auto Budget?',
        field: 'auto_budget',
        filter: true,
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.gameUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable Auto Budget' : 'Disable Auto Budget',
            params.newValue ? 'Enable' : 'Disable'
          ),
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'Use D-1 data?',
        field: 'use_yesterday_data',
        filter: true,
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.gameUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Use D-1 data' : 'Use latest data',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: 'Launch Date',
        field: 'launch_date',
        headerTooltip: 'Full Launch Date or Soft Launch/AB Test Launch date if game has neither been scaled',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        },
        cellRenderer: ({ value }) => toLocaleDateString(value),
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'First Time On Autobid',
        field: 'first_time_on_autobid',
        headerTooltip: 'The date at which the game has been enabled on Autobid for the first time',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        },
        cellRenderer: ({ value }) => toLocaleDateString(value),
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'Created At',
        field: 'created_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        },
        cellRenderer: ({ value }) => toLocaleDateString(value),
        hide: !window.current_user_attributes.admin
      }
    ],
    defaultColDef: {
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      minWidth: 125,
      autoHeight: true
    },
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled
    },
    components: { CheckboxRenderer },
    animateRows: true,
    pagination: true,
    paginationPageSize: 1000,
    domLayout: 'normal',
    editType: 'fullRow',
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onRowValueChanged: ev => {
      // The value of the cell is just versions, we need to reconcile its id.
      ev.data.autobid_version_id = autobidVersions.find(v => v.version === ev.data.version).id
      controller.updateRecord(ev, controller.gameUpdatePathValue)
    },
    onSelectionChanged: controller.updateBulkUpdateAutobidVersionButtonState,
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
