import { toPercentString } from '../../../helpers/convert'
import { compareDates } from '../../../helpers/check'

export const gameSelectionOptions = onSelectionChanged => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        filter: 'agNumberColumnFilter',
        pinned: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        headerName: 'Name',
        field: 'name',
        pinned: true,
        minWidth: 300
      },
      {
        headerName: 'Platform',
        field: 'platform',
        pinned: true
      },
      {
        headerName: 'Default Version',
        field: 'version'
      },
      {
        headerName: 'Default MO %',
        field: 'margin_objective',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value)
      },
      {
        headerName: 'Launch Date',
        field: 'launch_date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        }
      },
      {
        headerName: 'Disabled Campaigns',
        field: 'disabled_campaigns',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Major Spend Networks',
        field: 'major_spend_networks',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Auto Source Bids',
        field: 'auto_source_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Auto Campaign Bids',
        field: 'auto_campaign_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'UA Source Bids',
        field: 'ua_source_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'UA Campaign Bids',
        field: 'ua_campaign_bids',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Daily Installs',
        field: 'daily_installs',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Organic Installs',
        field: 'daily_organic_installs',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Daily Spends',
        field: 'daily_spends',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Daily Revenues',
        field: 'daily_revenues',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Daily Margin',
        field: 'daily_margin',
        filter: 'agNumberColumnFilter'
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      minWidth: 125
    },
    animateRows: true,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    pagination: true,
    paginationAutoPageSize: true,
    onSelectionChanged,
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
