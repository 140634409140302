import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'step2Header',
    'step3Header',
    'step1',
    'step2',
    'step3'
  ]

  INDEX_TO_TARGET_MAP = {
    1: { node: this.step1Target },
    2: { header: this.step2HeaderTarget, node: this.step2Target },
    3: { header: this.step3HeaderTarget, node: this.step3Target }
  }

  indexToTarget = index => this.INDEX_TO_TARGET_MAP[index]

  setHeaderFromActiveToInactive = element => {
    element.classList.replace('border-indigo-600', 'border-gray-200')
    element.firstElementChild.classList.replace('text-indigo-600', 'text-gray-500')
  }

  setHeaderFromInactiveToActive = element => {
    element.classList.replace('border-gray-200', 'border-indigo-600')
    element.firstElementChild.classList.replace('text-gray-500', 'text-indigo-600')
  }

  changeStep = ev => {
    const currentIndex = $(ev.target).closest('[data-current-index]').data('current-index')
    const gotoIndex = ev.target.dataset.index
    const currentStep = this.indexToTarget(currentIndex)
    const gotoStep = this.indexToTarget(gotoIndex)

    if (gotoStep.header) this.setHeaderFromInactiveToActive(gotoStep.header)
    if (currentIndex > gotoIndex) this.setHeaderFromActiveToInactive(currentStep.header)
    currentStep.node.classList.add('hidden')
    gotoStep.node.classList.remove('hidden')
  }
}
