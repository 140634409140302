import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import {
  historyGridOptions,
  biddingRulesHistoryGridOptions,
  ltvCorrectionRulesHistoryGridOptions
} from './history/grid_options'

export default class extends Controller {
  static targets = [
    'history',
    'ltvCorrectionRulesHistory',
    'biddingRulesHistory'
  ]

  static values = {
    getHistoryRecordsPath: String,
    getLtvCorrectionRulesHistoryRecordsPath: String,
    getBiddingRulesHistoryRecordsPath: String
  }

  async connect () {
    useDataGrid(this)

    const mutationObserver = new MutationObserver(
      () => this.gridApisByTarget.forEach(grid => grid.sizeColumnsToFit()))
    mutationObserver.observe(this.historyTarget, { attributes: true })

    await Promise.all([
      this.newGrid(this.historyTarget, historyGridOptions, this.getHistoryRecordsPathValue),
      this.newGrid(this.biddingRulesHistoryTarget, biddingRulesHistoryGridOptions, this.getBiddingRulesHistoryRecordsPathValue),
      this.newGrid(this.ltvCorrectionRulesHistoryTarget, ltvCorrectionRulesHistoryGridOptions, this.getLtvCorrectionRulesHistoryRecordsPathValue)
    ])
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }
}
