import { Controller } from 'stimulus'
import { getJson } from '../../helpers/request'
import { Chart } from 'chart.js'
import { useDataGrid } from '../mixins/datagrid'
import { gridOptions } from './index/capping_campaigns_grid_options'
import { getSelectValue } from '../../helpers/select'

const palette = require('google-palette')

export default class extends Controller {
  static targets = [
    'budgetChart',
    'gamesSelect',
    'gamesLoadingSpinner',
    'networksSelect',
    'networksLoadingSpinner',
    'campaignsSelect',
    'campaignsLoadingSpinner',
    'countriesSelect',
    'countriesLoadingSpinner',
    'cappingCampaignsGrid'
  ]

  static values = {
    getGlobalBudgetPath: String,
    getAvailableNetworksForGamePath: String,
    getCampaignsForGamePath: String,
    getCountriesForGamePath: String,
    getBudgetsForGamePath: String,
    getLatestCappingCampaignsPath: String
  }

  async connect () {
    useDataGrid(this)
    const api = await this.newGrid(this.cappingCampaignsGridTarget, gridOptions(this))
    api.showLoadingOverlay()

    const [campaigns] = await Promise.all([getJson(this.getLatestCappingCampaignsPathValue), this.updateCharts()])
    this.campaigns = campaigns
    api.setRowData(this.campaigns)
    api.redrawRows()
    api.hideOverlay()

    this.filterCampaignsDatatable()
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  resetChartsData () {
    Object.values(Chart.instances).forEach(chart => {
      chart.data.datasets = []
      chart.data.labels = []
      chart.update()
    })
  }

  emptyAndDisableSelect = target => {
    $(target).empty()
    $(target).prop('disabled', true)
    return () => $(target).prop('disabled', false)
  }

  setSelectValue = (target, value) => $(target).val(value).trigger('change')

  get selectedGameId () {
    const { gameId } = $(this.gamesSelectTarget).find(':selected').data()
    return gameId
  }

  get selectedNetworkIds () {
    const { networkIds } = $(this.networksSelectTarget).find(':selected').data()
    return networkIds
  }

  fillNetworkSelect = (availableNetworks, activeNetworks) => {
    const availableNetworksOption = new Option('All', 'All', false, false)
    availableNetworksOption.setAttribute('data-network-ids', `[${availableNetworks.map(v => v.network_id).join(',')}]`)

    const activeNetworksOption = new Option('All active', 'All active', true, true)
    activeNetworksOption.setAttribute('data-network-ids', `[${activeNetworks.map(v => v.network_id).join(',')}]`)

    ;[activeNetworksOption, availableNetworksOption]
      .concat(
        availableNetworks.map(v => {
          const option = new Option(v.ad_network, v.network_id, false, false)
          option.setAttribute('data-network-ids', `[${v.network_id}]`)
          return option
        })
      )
      .forEach(v => $(this.networksSelectTarget).append(v))
  }

  fetchNetworks = async (appId, value) => {
    const enableSelect = this.emptyAndDisableSelect(this.networksSelectTarget)
    this.toggleLoadingSpinner(this.networksLoadingSpinnerTarget)

    const path = this.getAvailableNetworksForGamePathValue + '?' + new URLSearchParams({ game_id: appId })
    const { available_networks, active_networks } = await getJson(path)

    this.fillNetworkSelect(available_networks, active_networks)

    if (value) this.setSelectValue(this.networksSelectTarget, value)

    this.toggleLoadingSpinner(this.networksLoadingSpinnerTarget)
    enableSelect()
  }

  fillCampaignsSelect = campaigns => {
    const allOption = new Option('All', 'All', true, true)
        ;[allOption]
      .concat(
        campaigns.map(c => new Option(`${c.campaign_id} -- ${c.campaign_name}`, c.campaign_id, false, false))
      )
      .forEach(v => (this.campaignsSelectTarget).append(v))
  }

  fillCountriesSelect = countries => {
    countries.map(c => new Option(c, c, false, false))
      .forEach(v => (this.countriesSelectTarget).append(v))
  }

  fetchCampaigns = async (appId, networkIds, value) => {
    const enableSelect = this.emptyAndDisableSelect(this.campaignsSelectTarget)
    this.toggleLoadingSpinner(this.campaignsLoadingSpinnerTarget)

    const path = this.getCampaignsForGamePathValue + '?' + new URLSearchParams({ game_id: appId, network_id: networkIds })
    const campaigns = await getJson(path)
    this.fillCampaignsSelect(campaigns)

    if (value) this.setSelectValue(this.campaignsSelectTarget, value)

    this.toggleLoadingSpinner(this.campaignsLoadingSpinnerTarget)
    enableSelect()
  }

  fetchCountries = async (appId, networkId, campaignId, value) => {
    const enableSelect = this.emptyAndDisableSelect(this.countriesSelectTarget)
    this.toggleLoadingSpinner(this.countriesLoadingSpinnerTarget)

    const path = this.getCountriesForGamePathValue + '?' + new URLSearchParams({ game_id: appId, network_id: [networkId], campaign_id: campaignId })
    const countries = await getJson(path)

    this.fillCountriesSelect(countries)
    this.toggleLoadingSpinner(this.countriesLoadingSpinnerTarget)

    if (value) this.setSelectValue(this.countriesSelectTarget, value)
    enableSelect()
  }

  onCampaignIdClick = async (appId, networkId, campaignId, country) => {
    this.setSelectValue(this.gamesSelectTarget, appId)
    await Promise.all([
      this.fetchNetworks(appId, networkId),
      this.fetchCampaigns(appId, networkId, campaignId),
      this.fetchCountries(appId, networkId, campaignId, country)
    ])

    await this.updateCharts()
    this.updateUrl()
  }

  toggleLoadingSpinner = target => {
    target.classList.toggle('hidden')
    target.classList.toggle('animate-spin')
  }

  updateVizAndUrl = async () => {
    this.filterCampaignsDatatable()
    this.updateUrl()
    await this.updateCharts()
  }

  onGameSelection = async () => {
    try {
      this.toggleLoadingSpinner(this.gamesLoadingSpinnerTarget)
      ;[this.campaignsSelectTarget, this.countriesSelectTarget].forEach(this.emptyAndDisableSelect)

      const gameId = this.selectedGameId
      if (gameId) await this.fetchNetworks(gameId)
      await this.updateVizAndUrl()
    } catch (ex) {
      console.error(ex)
      throw ex
    } finally {
      this.toggleLoadingSpinner(this.gamesLoadingSpinnerTarget)
    }
  }

  onNetworkSelection = async () => {
    try {
      this.toggleLoadingSpinner(this.networksLoadingSpinnerTarget)
      ;[this.campaignsSelectTarget, this.countriesSelectTarget].forEach(this.emptyAndDisableSelect)

      const gameId = this.selectedGameId
      const networkIds = this.selectedNetworkIds

      if (gameId && networkIds.length === 1) { await this.fetchCampaigns(gameId, networkIds) }

      await this.updateVizAndUrl()
    } catch (ex) {
      console.error(ex)
      throw ex
    } finally {
      this.toggleLoadingSpinner(this.networksLoadingSpinnerTarget)
    }
  }

  onCampaignSelection = async () => {
    try {
      this.toggleLoadingSpinner(this.campaignsLoadingSpinnerTarget)
      this.emptyAndDisableSelect(this.countriesSelectTarget)

      const gameId = this.selectedGameId
      const networkIds = this.selectedNetworkIds
      const campaignId = getSelectValue(this.campaignsSelectTarget)

      if (campaignId !== 'All') { await this.fetchCountries(gameId, networkIds[0], campaignId) }

      await this.updateVizAndUrl()
    } catch (ex) {
      console.error(ex)
      throw ex
    } finally {
      this.toggleLoadingSpinner(this.campaignsLoadingSpinnerTarget)
    }
  }

  onCountrySelection = async () => {
    try {
      this.toggleLoadingSpinner(this.countriesLoadingSpinnerTarget)
      await this.updateVizAndUrl()
    } catch (ex) {
      console.error(ex)
      throw ex
    } finally {
      this.toggleLoadingSpinner(this.countriesLoadingSpinnerTarget)
    }
  }

  updateUrl = () => {
    const gameId = getSelectValue(this.gamesSelectTarget)
    const networkId = getSelectValue(this.networksSelectTarget)
    const campaignId = getSelectValue(this.campaignsSelectTarget)
    const country = getSelectValue(this.countriesSelectTarget)

    const params = {}
    if (gameId) params.game_id = gameId
    if (networkId) params.network_id = networkId
    if (campaignId) params.campaign_id = campaignId
    if (country) params.country = country

    window.history.pushState({}, null, '/budgets?' + new URLSearchParams(params))
  }

  filterCampaignsDatatable = () => {
    const gameId = this.selectedGameId
    const gridApi = this.gridApisByTarget.get(this.cappingCampaignsGridTarget)
    const appIdFilter = gridApi.getFilterInstance('app_id')
    appIdFilter.setModel({ type: 'equals', filter: gameId ?? '' })

    const networkIds = this.selectedNetworkIds
    if (gameId) { gridApi.setRowData(this.campaigns.filter(c => networkIds.includes(c.network_id) && gameId === c.app_id)) } else { gridApi.setRowData(this.campaigns.filter(c => networkIds.includes(c.network_id))) }

    gridApi.onFilterChanged()
  }

  updateCharts = async () => {
    const query = {}

    const gameId = this.selectedGameId
    if (gameId) query.game_id = gameId

    const networkIds = this.selectedNetworkIds
    if (networkIds) query.network_ids = networkIds

    const campaignId = getSelectValue(this.campaignsSelectTarget)
    if (campaignId) query.campaign_id = campaignId

    const country = getSelectValue(this.countriesSelectTarget)
    if (country) query.country = country

    const path = this.getGlobalBudgetPathValue + '?' + new URLSearchParams(query)
    const data = await getJson(path)
    this.resetChartsData()

    const dimensions = ['total_budget', 'total_spend']
    const colors = palette('tol', dimensions.length).map(hex => '#' + hex)
    const datasets = dimensions.map((dim, i) => ({
      label: dim,
      data: data.map(o => ({ x: o.spend_date, y: o[dim] })),
      backgroundColor: colors[i],
      color: colors[i],
      borderColor: colors[i],
      fill: false
    }))

    const chart = Object.values(Chart.instances).find(
      o => o.canvas.dataset['budgets-IndexTarget'] ===
                this.budgetChartTarget.dataset['budgets-IndexTarget']
    )

    chart.data.labels = datasets[0].data.map(o => o.x)
    chart.data.datasets = datasets
    chart.update()
  }
}
