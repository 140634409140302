import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'
import { toPercentString, toLocaleDateString } from '../../../helpers/convert'
import { valueExists } from '../../../helpers/check'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Network',
        field: 'network_name',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        minWidth: 100,
        cellRenderer: ({ value }) => valueExists(value) ? value : '-'
      },
      {
        headerName: 'Campaign',
        field: 'campaign_name',
        cellRenderer: ({ value }) => valueExists(value) ? value : '-',
        minWidth: 100,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Adgroup',
        field: 'adgroup_name',
        cellRenderer: ({ value }) => valueExists(value) ? value : '-',
        minWidth: 90,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' }
      },
      {
        headerName: 'Country Group',
        field: 'country_group',
        valueGetter: params => valueExists(params.data.country_group) ? params.data.country_group : '-'
      },
      {
        headerName: 'Country',
        field: 'country',
        valueGetter: params => `${params.data.country_code} ${params.data.country_code === 'ALL' ? params.data?.targeted_countries?.split(',')?.join(' ') : ''}`,
        cellRenderer: ({ data }) => valueExists(data.country_code) ? data.country_code : '-'
      },
      {
        headerName: 'Bid Type',
        field: 'bid_type',
        minWidth: 140,
        cellRenderer: ({ value }) => {
          const color = {
            default: 'green',
            roas_day: 'blue',
            roas_single_value: 'blue',
            roas: 'blue',
            roas_auto: 'yellow',
            auto: 'red'
          }[value]

          return `
            <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${color}-100 text-${color}-800">
              ${value}
            </span>
          `
        },
        hide: !window.current_user_attributes.admin
      },
      {
        headerName: 'Network ROAS Window',
        field: 'roas_day',
        minWidth: 120,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['d0', 'd3', 'd7']
        },
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        editable: (params) => controller.isRoas(params.data.bid_type) && params.data.network_id !== 12,
        cellRenderer: ({ value, data }) => {
          if (!controller.isRoas(data.bid_type) || data.network_id === 12) {
            return ''
          }

          if (!valueExists(value)) {
            return '?'
          }

          return `
            <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
              ${value}
            </span>
          `
        }
      },
      {
        headerName: 'Auto',
        field: 'enabled',
        minWidth: 60,
        cellRenderer: ({ value }) => value ? '✅' : '❌'
      },
      {
        headerName: 'Autobid ROAS Window',
        field: 'roas_dx',
        minWidth: 120,
        cellRenderer: ({ value, node }) => node.selected ? '' : valueExists(value) ? value : '-'
      },
      {
        headerName: 'Autobid Target ROAS',
        field: 'roas_target',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => parseFloat(valueA) - parseFloat(valueB),
        cellRenderer: ({ value, node }) => node.selected ? '' : valueExists(value) ? toPercentString(value, 0) : '-'
      },
      {
        headerName: 'Estimated D120 ROAS',
        headerTooltip: 'Estimated D120 ROAS (including organics) calculated with the most recent LTV estimation.',
        field: 'estimated_d120_roas',
        filter: 'agNumberColumnFilter',
        minWidth: 120,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => parseFloat(valueA) - parseFloat(valueB),
        valueGetter: params => params.node.selected ? '' : controller.getEstimatedD120Roas(params.data.roas_dx, params.data.roas_target / 100, params.data)
      },
      {
        headerName: 'Estimated Future Bid',
        headerTooltip: 'Estimated future CPI or ROAS calculated with the most recent LTV estimation. Note that the final bid will use newer data and that changes will be limited by capping rules.',
        field: 'estimated_bid',
        filter: 'agNumberColumnFilter',
        minWidth: 110,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => parseFloat(valueA) - parseFloat(valueB),
        valueGetter: params => params.node.selected ? '' : controller.getEstimatedBid(params.data.roas_dx, params.data.roas_target / 100, params.data)
      },
      {
        headerName: 'Last Bid',
        headerTooltip: 'The most recent real CPI available (D-3) or the last ROAS/budget sent via Bidshake.',
        field: 'last_bid',
        filter: 'agNumberColumnFilter',
        minWidth: 110,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => parseFloat(valueA) - parseFloat(valueB),
        valueGetter: params => controller.getBid(params.data)
      },
      {
        headerName: 'Campaign Daily Spend',
        headerTooltip: 'Daily spend averaged over the last seven days at campaign level.',
        field: 'spend',
        filter: 'agNumberColumnFilter',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => parseFloat(valueA) - parseFloat(valueB),
        valueGetter: params => valueExists(params.data.spend) ? params.data.spend.toFixed(0) : '-'
      },
      {
        headerName: 'Last Update',
        field: 'updated_at',
        filter: false,
        minWidth: 60,
        cellRenderer: ({ value }) => toLocaleDateString(value)
      }
    ],
    onPaginationChanged: () => controller.onUpdateChange(),
    onRowSelected: async (params) => {
      const roasDx = params.node.selected
        ? controller.getBulkUpdateRoasDx()
        : params.data.roas_dx

      const roasTarget = params.node.selected
        ? controller.getBulkUpdateRoasTarget()
        : params.data.roas_target

      const roasEnabled = params.node.selected
        ? controller.getBulkUpdateRoasEnabled()
        : params.data.enabled

      controller.refreshRow(roasDx, roasTarget, roasEnabled, params.node)

      controller.toggleBulkActions()
    },
    onCellValueChanged: ev => {
      controller.updateRoasDay(ev)
    },
    isRowSelectable: (params) => {
      if (!controller.isRoas(params.data.bid_type) || params.data.network_id === 12) {
        return true
      }

      if (params.data.roas_day) {
        return true
      }

      return false
    },
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true,
      minWidth: 60
    },
    rowClassRules: {
      'opacity-80': params => !params.data.enabled,
      'bg-red-100': params => controller.isRoas(params.data.bid_type) && !params.data.roas_day && params.data.network_id !== 12
    },
    components: { CheckboxRenderer },
    editType: 'fullRow',
    animateRows: true,
    pagination: true,
    paginationPageSize: 50,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    rowSelection: 'multiple',
    tooltipShowDelay: 0
  }
}
