import { toBooleanString, toPercentString } from '../../../helpers/convert'
import { CampaignIdRenderer } from '../campaign_id_renderer'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'App ID',
        field: 'app_id',
        filter: 'agNumberColumnFilter',
        width: 100
      },
      {
        headerName: 'App Name',
        field: 'app_name',
        filter: true
      },
      {
        headerName: 'Platform',
        field: 'platform',
        filter: true,
        width: 100
      },
      {
        headerName: 'Autobid?',
        field: 'is_enabled',
        filter: true,
        cellRenderer: ({ value }) => toBooleanString(value),
        width: 100
      },
      {
        headerName: 'Auto Budget?',
        field: 'auto_budget',
        filter: true,
        cellRenderer: ({ value }) => toBooleanString(value),
        width: 100
      },
      {
        headerName: 'Ad Network',
        field: 'ad_network',
        filter: true,
        width: 100
      },
      {
        headerName: 'Campaign ID',
        field: 'campaign_id',
        filter: true,
        cellRenderer: 'CampaignIdRenderer',
        cellRendererParams: {
          onCampaignIdClick: controller.onCampaignIdClick
        }
      },
      {
        headerName: 'Campaign Name',
        field: 'campaign_name',
        filter: true
      },
      {
        headerName: 'Country',
        field: 'country',
        filter: true,
        width: 100
      },
      {
        headerName: 'Max Consumed Budget %',
        field: 'max_consumed_budget_percent',
        filter: true,
        cellRenderer: ({ value }) => toPercentString(value),
        width: 100
      },
      {
        headerName: '# capped days',
        field: 'high_consumed_budget_days',
        filter: true,
        width: 100
      }
    ],
    components: {
      CampaignIdRenderer
    },
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      autoHeight: true
    },
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
