export const performanceGridOptions = {
  columnDefs: [
    {
      headerName: 'ID',
      field: 'id',
      pinned: true,
      filter: 'agNumberColumnFilter',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: 'Name',
      field: 'name',
      pinned: true,
      minWidth: 250,
      cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
      cellRenderer: ({ data, value }) => {
        return `
                            <a href="/autobid_games/${data.id}/history" class="cursor-pointer underline">
                            ${value} 
                            </a>
                        `
      }
    },
    {
      headerName: 'Platform',
      field: 'platform',
      pinned: true
    },
    {
      headerName: 'Daily Installs',
      field: 'daily_installs',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Organic Installs',
      field: 'daily_organic_installs',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Daily Spends',
      field: 'daily_spends',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Daily Revenues',
      field: 'daily_revenues',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Daily Margin',
      field: 'daily_margin',
      filter: 'agNumberColumnFilter'
    }
  ],
  defaultColDef: {
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    minWidth: 125,
    autoHeight: true
  },
  rowClassRules: {
    'opacity-50': params => !params.data.is_enabled
  },
  animateRows: true,
  pagination: true,
  paginationPageSize: 1000,
  domLayout: 'normal',
  editType: 'fullRow',
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  getRowNodeId: data => data.id,
  enableCellTextSelection: true,
  ensureDomOrder: true
}
