import { toLocaleDateTimeString, toPercentString, toBooleanString } from '../../../helpers/convert'
import { valueExists, compareDates } from '../../../helpers/check'

export const historyGridOptions = {
  columnDefs: [
    {
      headerName: 'Who did it?',
      field: 'current_user',
      wrapText: true,
      minWidth: 200,
      cellRenderer: ({ value }) => value ? value.replace(/@.+/, '') : '-'
    },
    {
      headerName: 'UA Associate',
      field: 'ua_associate',
      cellRenderer: ({ value }) => value ? value.replace(/@.+/, '') : '-'
    },
    {
      headerName: 'Default Version',
      field: 'default_version'
    },
    {
      headerName: 'Roas Target',
      field: 'roas_target',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => value ? toPercentString(value * 100) : '-'
    },
    {
      headerName: 'Roas Window',
      field: 'roas_dx',
      filter: 'agNumberColumnFilter',
      minWidth: 200,
      cellRenderer: ({ value }) => value || '-'
    },
    {
      headerName: 'Experiment',
      field: 'experiment_id',
      cellRenderer: ({ value }) => {
        const link = `<a href="/experiments/${value}" data-turbolinks="false" class="cursor-pointer underline">${value}</a>`
        return value ? link : '-'
      },
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Experiment Version',
      field: 'experiment_version'
    },
    {
      headerName: 'Experiment MO %',
      field: 'experiment_margin_objective',
      cellRenderer: ({ value }) => toPercentString(value),
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Enabled?',
      field: 'is_enabled',
      cellRenderer: ({ value }) => toBooleanString(value)
    },
    {
      headerName: 'No Experiment?',
      field: 'exclude_from_experiment',
      cellRenderer: ({ value }) => toBooleanString(value)
    },
    {
      headerName: 'Auto Budget?',
      field: 'auto_budget',
      cellRenderer: ({ value }) => toBooleanString(value)
    },
    {
      headerName: 'Use D-1 data?',
      field: 'use_yesterday_data',
      cellRenderer: ({ value }) => toBooleanString(value)
    },
    {
      headerName: 'LTV Correction',
      field: 'ltv_correction',
      cellRenderer: ({ value }) => toPercentString(value)
    },
    {
      headerName: 'Bid at DX',
      field: 'dx'
    },
    {
      headerName: 'DX Target',
      field: 'dx_target',
      cellRenderer: ({ value }) => toPercentString(value * 100)
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      cellRenderer: ({ value }) => toLocaleDateTimeString(value),
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: compareDates
      }
    }
  ],
  defaultColDef: {
    floatingFilter: true,
    suppressMenu: true,
    filter: 'agTextColumnFilter',
    sortable: true,
    autoHeight: true,
    minWidth: 120
  },
  animateRows: true,
  pagination: true,
  paginationAutoPageSize: true,
  getRowNodeId: data => data.id,
  enableCellTextSelection: true,
  ensureDomOrder: true
}

export const ltvCorrectionRulesHistoryGridOptions = {
  columnDefs: [
    {
      headerName: 'Who did it?',
      field: 'current_user',
      minWidth: 225,
      cellRenderer: ({ value }) => value ? value.replace(/@.+/, '') : '-'
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: ({ value }) => {
        const color = (value === 'create') ? 'green' : (value === 'delete') ? 'red' : 'yellow'
        return `
                        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${color}-100 text-${color}-800">
                            ${value}
                        </span>
                    `
      }
    },
    {
      headerName: 'Level',
      field: 'level',
      minWidth: 175,
      cellRenderer: ({ value }) => {
        const color = {
          campaign: 'green',
          country: 'red',
          country_group: 'yellow',
          country_group_network: 'blue',
          country_network: 'purple'
        }[value]

        return `
                        <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${color}-100 text-${color}-800">
                            ${value}
                        </span>
                    `
      }
    },
    {
      headerName: 'Country Group',
      valueGetter: params => valueExists(params.data.country_group) ? params.data.country_group : '-'
    },
    {
      headerName: 'Country Code',
      width: 500,
      cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
      valueGetter: params => valueExists(params.data.country)
        ? `${params.data.country.country_code} - ${params.data.country.country_name || 'All'}`
        : '-'
    },
    {
      headerName: 'Network',
      field: 'network.name',
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'Campaign',
      field: 'campaign_name',
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'LTV correction',
      field: 'ltv_correction',
      cellRenderer: ({ value }) => toPercentString(value * 100),
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      width: 350,
      cellRenderer: ({ value }) => toLocaleDateTimeString(value),
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: compareDates
      }
    }
  ],
  defaultColDef: {
    floatingFilter: true,
    suppressMenu: true,
    filter: 'agTextColumnFilter',
    sortable: true,
    autoHeight: true,
    minWidth: 120
  },
  animateRows: true,
  pagination: true,
  paginationAutoPageSize: true,
  getRowNodeId: data => data.id,
  enableCellTextSelection: true,
  ensureDomOrder: true
}

export const biddingRulesHistoryGridOptions = {
  columnDefs: [
    {
      headerName: 'Who did it?',
      field: 'user',
      minWidth: 200,
      cellRenderer: ({ value }) => value ? value.replace(/@.+/, '') : '-'
    },
    {
      headerName: 'Network',
      field: 'network_name',
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'Campaign',
      field: 'campaign_name',
      width: 300,
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'Adgroup',
      field: 'adgroup_name',
      width: 300,
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'Country Group',
      width: 100,
      cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
      valueGetter: params => valueExists(params.data.country_group) ? params.data.country_group : '-'
    },
    {
      headerName: 'Country Code',
      field: 'country_code',
      cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
      width: 100
    },
    {
      headerName: 'Autobid ROAS Window',
      field: 'roas_dx',
      minWidth: 200,
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'Autobid Target ROAS',
      field: 'roas_target',
      filter: 'agNumberColumnFilter',
      minWidth: 200,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => parseFloat(valueA) - parseFloat(valueB),
      cellRenderer: ({ value }) => valueExists(value) ? toPercentString(value * 100, 0) : '-'
    },
    {
      headerName: 'Platform ROAS Window',
      field: 'roas_day',
      filter: 'agNumberColumnFilter',
      minWidth: 200,
      cellRenderer: ({ value }) => valueExists(value) ? value : '-'
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      width: 350,
      cellRenderer: ({ value }) => toLocaleDateTimeString(value),
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: compareDates
      }
    }
  ],
  defaultColDef: {
    floatingFilter: true,
    suppressMenu: true,
    filter: 'agTextColumnFilter',
    sortable: true,
    autoHeight: true,
    minWidth: 100
  },
  animateRows: true,
  pagination: true,
  paginationAutoPageSize: true,
  getRowNodeId: data => data.id,
  enableCellTextSelection: true,
  ensureDomOrder: true
}
