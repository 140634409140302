import { Controller } from 'stimulus'

import { getJson, putJson } from '../../helpers/request'
import { useDataGrid } from '../mixins/datagrid'
import { handleDisabledButtonState } from '../../helpers/button_states'
import { uaGridOptions } from './index/ua_grid_options'
import { bidsGridOptions } from './index/bids_grid_options'
import { performanceGridOptions } from './index/performance_grid_options'

export default class extends Controller {
  static targets = [
    'uaGrid',
    'bidsGrid',
    'performanceGrid',
    'modalPlaceholder',
    'numberOfGames',
    'autobidVersionSelect',
    'uaAssociateSelect',
    'bulkUpdateAutobidVersionButton',
    'bulkUpdateRoasButton',
    'autobidRoasWindowSelect',
    'autobidRoasTargetInput'
  ]

  static values = {
    bulkUpdatePath: String,
    gameUpdatePath: String,
    getGamesPath: String,
    enabledAutobidVersionsPath: String,
    allUa: Array
  }

  async connect () {
    useDataGrid(this)

    const [versions, games] = await Promise.all([getJson(this.enabledAutobidVersionsPathValue), getJson(this.getGamesPathValue)])
    this.initializeAutobidVersionsSelect(versions)
    this.initializeRoasWindowSelect(games[0].roas_windows)

    const apis = await Promise.all([
      this.newGrid(this.uaGridTarget, uaGridOptions(this, versions, this.allUaValue)),
      this.newGrid(this.bidsGridTarget, bidsGridOptions),
      this.newGrid(this.performanceGridTarget, performanceGridOptions)
    ])

    apis.forEach(api => api.showLoadingOverlay())

    this.numberOfGamesTarget.innerText = games.length

    apis.forEach(api => {
      api.setRowData(games)
      api.sizeColumnsToFit()
    })

    const uaAssociate = $(this.uaAssociateSelectTarget).val()
    this.updateUrl({ ua_associate: uaAssociate })

    if (!games.some(({ ua_associate }) => ua_associate === uaAssociate)) { $(this.uaAssociateSelectTarget).val('All').trigger('change') }

    this.onUaAssociateSelect()
    apis.forEach(api => api.hideOverlay())
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  onTabChange = () => {
    this.gridApisByTarget.forEach(api => api.sizeColumnsToFit())
  }

  updateUrl = params => {
    window.history.pushState({}, null, '/autobid_games?' + new URLSearchParams(params))
  }

  initializeAutobidVersionsSelect = autobidVersions => {
    autobidVersions.forEach(o => {
      const option = new Option(o.version, o.id, false, false)
      $(this.autobidVersionSelectTarget).append(option)
    })
  }

  initializeRoasWindowSelect = roasWindows => {
    roasWindows.forEach(o => {
      const option = new Option(o, o, false, false)
      $(this.autobidRoasWindowSelectTarget).append(option)
    })
  }

  onUaAssociateSelect = () => {
    const api = this.gridApisByTarget.get(this.uaGridTarget)
    const filter = api.getFilterInstance('ua_associate')
    const uaAssociate = $(this.uaAssociateSelectTarget).val()
    this.updateUrl({ ua_associate: uaAssociate })

    uaAssociate === 'All'
      ? filter.setModel(null)
      : filter.setModel({ type: 'equals', filter: $(this.uaAssociateSelectTarget).val() })
    api.onFilterChanged()
  }

  updateBulkUpdateAutobidVersionButtonState = () => {
    const isAutobidVersionSelected = !!this.autobidVersionSelectTarget.value
    const areSomeRowsSelected =
      this.gridApisByTarget.get(this.uaGridTarget).getSelectedNodes().length > 0
    const shouldBeDisabled = !(isAutobidVersionSelected && areSomeRowsSelected)
    handleDisabledButtonState(this.bulkUpdateAutobidVersionButtonTarget, shouldBeDisabled)
  }

  updateBulkUpdateRoasButtonState = () => {
    const isAutobidRoasWindowSelected = !!this.autobidRoasWindowSelectTarget.value
    const isAutobidRoasTargetSelected = !!this.autobidRoasTargetInputTarget.value
    const areSomeRowsSelected =
      this.gridApisByTarget.get(this.uaGridTarget).getSelectedNodes().length > 0
    const shouldBeDisabled = !(isAutobidRoasWindowSelected && isAutobidRoasTargetSelected && areSomeRowsSelected)
    handleDisabledButtonState(this.bulkUpdateRoasButtonTarget, shouldBeDisabled)
  }

  bulkUpdate = async (attributes, target) => {
    const gridApi = this.gridApisByTarget.get(this.uaGridTarget)
    const selectedNodes = gridApi.getSelectedNodes()
    const selectedNodesData = selectedNodes.map(n => n.data)

    gridApi.showLoadingOverlay()
    const payload = { ids: selectedNodesData.map(o => o.id), attributes }

    try {
      const data = await putJson(this.bulkUpdatePathValue, payload, target)
      data.forEach(v => selectedNodes.find(n => n.data.id === v.id).setData(v))
    } catch (error) {
      console.log(error)
    } finally {
      gridApi.hideOverlay()
    }
  }

  onBulkUpdateRoasButtonClick = async () => {
    const roasTarget = parseFloat(this.autobidRoasTargetInputTarget.value) / 100

    await this.bulkUpdate({ roas_target: roasTarget, roas_dx: this.autobidRoasWindowSelectTarget.value }, this.bulkUpdateRoasButton)
  }

  onBulkUpdateAutobidVersionButtonClick = async () => {
    const autobidVersionId = this.autobidVersionSelectTarget.value
    await this.bulkUpdate({ autobid_version_id: autobidVersionId }, this.bulkUpdateAutobidVersionButtonTarget)
  }
}
