import { valueExists } from './check'

export const toLocaleDateString = value => {
  return valueExists(value) ? new Date(value).toLocaleDateString() : '-'
}

export const toLocaleDateTimeString = value => {
  return valueExists(value) ? new Date(value).toLocaleString() : '-'
}

export const toPercentString = (value, precision = 1) => {
  return valueExists(value) && !isNaN(value) ? `${parseFloat(value).toFixed(precision)}%` : '-'
}

export const toBooleanString = value => {
  if (!valueExists(value)) {
    return '-'
  }
  return value ? '✔' : '✖'
}

export const toRankString = value => {
  switch (value) {
    case 1:
      return `${value} 🥇`
    case 2:
      return `${value} 🥈`
    case 3:
      return `${value} 🥉`
    default:
      return `${value}`
  }
}

export const toTimeDurationString = value => {
  if (!valueExists(value)) {
    return '-'
  }

  if (value <= 60) {
    return `${value}s`
  }

  if (value <= 60 * 60) {
    const min = Math.floor(value / 60)
    const sec = value - min * 60
    return `${min}m ${sec}s`
  }

  const hour = Math.floor(value / 60 / 60)
  const remain = value - hour * 60 * 60
  const min = Math.floor(remain / 60)
  const sec = remain - min * 60
  return `${hour}h ${min}m ${sec}s`
}

export const toMemoryString = value => {
  if (!valueExists(value)) {
    return '-'
  }

  if (value <= 1024) {
    return `${value} MiB`
  }

  const gib = (value / 1024).toFixed(2)
  return `${gib} GiB`
}

export const toStatusString = value => {
  if (!valueExists(value)) {
    return '-'
  }

  if (value === 'succeeded') {
    return value + ' ✔'
  }

  if (value === 'failed') {
    return value + ' ✖'
  }

  return value + ' ⚑'
}
