export class AppNameRenderer {
  init (params) {
    this.params = params
    const div = document.createElement('div')
    div.textContent = params.value
    div.className = 'text-blue-500 underline cursor-pointer'
    div.addEventListener('click', ev => this.onAppNameClick(ev))

    this.eGui = div
    this.params.element = this.eGui
  }

  getGui () {
    return this.eGui
  }

  destroy () {
  }

  onAppNameClick () {
    const { app_id, platform } = this.params.data
    this.params.onAppNameClick(app_id, platform)
  }
}
