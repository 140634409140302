import { toLocaleDateString } from '../../../helpers/convert'
import { compareDates } from '../../../helpers/check'
import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'

export const infoGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        filter: 'agNumberColumnFilter',
        width: 125,
        pinned: true
      },
      {
        headerName: 'Name',
        field: 'hubscale_name',
        cellRenderer: ({ data, value }) => {
          return `<a href="/networks/${data.id}" data-turbolinks="false" class="cursor-pointer underline">${value}</a>`
        },
        width: 150,
        minWidth: 150,
        pinned: true
      },
      {
        headerName: 'Budget Level',
        field: 'budget_level',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['campaign', 'adgroup', 'country']
        }
      },
      {
        headerName: 'Bid Type',
        field: 'bid_type',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['cpi', 'budget']
        }
      },
      {
        headerName: 'Bid Level',
        field: 'bid_level',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['campaign', 'adgroup', 'source']
        }
      },
      {
        headerName: 'Bid Platform',
        field: 'bid_platform',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['android', 'ios', 'all']
        }
      },
      {
        headerName: 'Min Bid',
        field: 'min_bid',
        editable: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Bid Decimal Digits',
        field: 'bid_decimal_digits',
        editable: true,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Country Targeting',
        field: 'support_country_targeting',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.networkUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Support Country Targeting' : 'Unsupport Country Targeting',
            params.newValue ? 'Support' : 'Unsupport'
          )
      },
      {
        headerName: 'ROAS?',
        field: 'roas',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.networkUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable ROAS' : 'Disable ROAS',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: 'Enabled?',
        field: 'is_enabled',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.networkUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable Network' : 'Disable Network',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: 'Budget Enabled?',
        field: 'budget_enabled',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.networkUpdatePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Enable Budget Management' : 'Disable Budget Management',
            params.newValue ? 'Enable' : 'Disable'
          )
      },
      {
        headerName: 'Created At',
        field: 'created_at',
        cellRenderer: ({ value }) => toLocaleDateString(value),
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: compareDates
        }
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      filter: true,
      minWidth: 125,
      autoHeight: true
    },
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled
    },
    components: { CheckboxRenderer },
    animateRows: true,
    editType: 'fullRow',
    pagination: true,
    paginationPageSize: 100,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    onRowValueChanged: ev => controller.updateRecord(ev, controller.networkUpdatePathValue),
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
