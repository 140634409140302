export class CampaignIdRenderer {
  init (params) {
    this.params = params
    const div = document.createElement('div')
    div.textContent = params.value
    div.className = 'text-blue-500 underline cursor-pointer'
    div.addEventListener('click', ev => this.onCampaignIdClick(ev))

    this.eGui = div
    this.params.element = this.eGui
  }

  getGui () {
    return this.eGui
  }

  destroy () {
  }

  onCampaignIdClick () {
    const { app_id, network_id, campaign_id, country } = this.params.data
    this.params.onCampaignIdClick(app_id, network_id, campaign_id, country)
  }
}
