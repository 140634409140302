const addPeriodCols = (columnDefs, controller) => {
  if (controller.type === 'switchback') {
    for (let i = 0; i < controller.periodsCount; i++) {
      columnDefs.push({
        headerName: `Period ${i + 1}`,
        field: `period_${i + 1}`,
        cellRenderer: ({ i, data, value }) => data.treatments.find(t => t.period_col === i + 1).treatment_1,
        cellRendererParams: { i }
      })
    }
  }
}

export const splitGridOptions = controller => {
  let columnDefs = [
    {
      headerName: 'App ID',
      field: 'app_id'
    },
    {
      headerName: 'Pair Id',
      field: 'pair_id',
      hide: controller.type !== 'switchback'
    }
  ]

  addPeriodCols(columnDefs, controller)

  columnDefs = columnDefs.concat([
    {
      headerName: 'Margin Objective',
      field: 'margin_objective',
      hide: controller.type === 'switchback'
    },
    {
      headerName: 'Predicted Margin',
      field: 'total_margin',
      cellRenderer: ({ value }) => value.toFixed(2)
    },
    {
      headerName: 'Calendar Margin',
      field: 'calendar_margin',
      cellRenderer: ({ value }) => value.toFixed(2)
    },
    {
      headerName: 'Total Spends',
      field: 'total_spends',
      cellRenderer: ({ value }) => value.toFixed(2)
    },
    {
      headerName: 'Total Revenues',
      field: 'total_revenues',
      cellRenderer: ({ value }) => value.toFixed(2)
    },
    {
      headerName: 'Total Installs',
      field: 'total_installs'
    },
    {
      headerName: 'Organic Installs',
      field: 'organic_installs'
    }
  ])

  const options = {}
  if (controller.type === 'switchback') {
    options.domLayout = 'autoHeight'
  } else {
    options.paginationAutoPageSize = true
    options.pagination = true
  }

  return {
    columnDefs,
    defaultColDef: {
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true
    },
    animateRows: true,
    suppressRowClickSelection: true,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    ...options
  }
}
