import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tabNav', 'tabContent']

  change = ev => {
    const currentTab = this.currentTabElement
    currentTab.classList.remove(...this.activeClasses)
    currentTab.classList.add(...this.inactiveClasses)

    ev.target.classList.remove(...this.inactiveClasses)
    ev.target.classList.add(...this.activeClasses)
    this.setCurrentTab(ev.target.dataset.index)
    this.setTabContent(ev.target.dataset.index)
  }

  setTabContent = index => {
    const [toShow, toHide] = Array.from(this.tabContentTarget.childNodes).reduce(
      (result, element) => {
        result[element.dataset.index === index ? 0 : 1].push(element)
        return result
      },
      [[], []]
    )

    toShow.forEach(v => v.classList.remove('hidden'))
    toHide.forEach(v => v.classList.add('hidden'))
  }

  setCurrentTab = index => {
    this.data.set('current', index)
  }

  get currentTabElement () {
    return Array.from(this.tabNavTarget.children).find(
      c => c.dataset.index === this.data.get('current')
    )
  }

  get activeClasses () {
    return this.data.get('active-classes').split(' ')
  }

  get inactiveClasses () {
    return this.data.get('inactive-classes').split(' ')
  }
}
