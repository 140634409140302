export const bidsGridOptions = {
  columnDefs: [
    {
      headerName: 'ID',
      field: 'id',
      pinned: true,
      filter: 'agNumberColumnFilter',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: 'Name',
      field: 'name',
      pinned: true,
      minWidth: 250,
      cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
      cellRenderer: ({ data, value }) => {
        return `
                            <a href="/autobid_games/${data.id}/history" class="cursor-pointer underline">
                            ${value} 
                            </a>
                        `
      }
    },
    {
      headerName: 'Platform',
      field: 'platform',
      pinned: true
    },
    {
      headerName: 'Disabled Campaigns',
      field: 'disabled_campaigns',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Major Spend Networks',
      field: 'major_spend_networks',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Auto Source Bids',
      field: 'auto_source_bids',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Auto Campaign Bids',
      field: 'auto_campaign_bids',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'UA Source Bids',
      field: 'ua_source_bids',
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'UA Campaign Bids',
      field: 'ua_campaign_bids',
      filter: 'agNumberColumnFilter'
    }
  ],
  defaultColDef: {
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    sortable: true,
    minWidth: 125,
    autoHeight: true
  },
  rowClassRules: {
    'opacity-50': params => !params.data.is_enabled
  },
  animateRows: true,
  pagination: true,
  paginationPageSize: 1000,
  domLayout: 'normal',
  editType: 'fullRow',
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  getRowNodeId: data => data.id,
  enableCellTextSelection: true,
  ensureDomOrder: true
}
