import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'
import { toPercentString, toBooleanString } from '../../../helpers/convert'
import { AppNameRenderer } from './app_name_renderer'

export const organicInstallsGamesGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Split ID',
        field: 'id',
        filter: 'agNumberColumnFilter',
        hide: true,
        pinned: true
      },
      {
        headerName: 'App ID',
        field: 'app_id',
        filter: 'agNumberColumnFilter',
        pinned: true
      },
      {
        headerName: 'App Name',
        field: 'app_name',
        pinned: true,
        minWidth: 175,
        cellRenderer: 'AppNameRenderer',
        cellRendererParams: {
          onAppNameClick: controller.onAppNameClick
        }
      },
      {
        headerName: 'Platform',
        field: 'platform',
        pinned: true
      },
      {
        headerName: 'Group ID',
        field: 'group_id',
        filter: 'agNumberColumnFilter',
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Version',
        field: 'version',
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'MO %',
        field: 'margin_objective',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Group Enabled?',
        field: 'is_group_enabled',
        cellRenderer: ({ value }) => toBooleanString(value),
        hide: controller.type === 'switchback'
      },
      {
        headerName: 'Removed?',
        field: 'is_removed',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.updateExperimentSplitPathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Remove From Experiment' : 'Add Back To Experiment',
            params.newValue ? 'Remove' : 'Add Back'
          )
      },
      {
        headerName: 'Organic Installs Before',
        field: 'daily_organic_installs_before',
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Organic Installs After',
        field: 'daily_organic_installs_after',
        filter: 'agNumberColumnFilter',
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: 'Organic Installs Δ %',
        field: 'organic_installs_change_percent',
        filter: 'agNumberColumnFilter',
        cellRenderer: ({ value }) => toPercentString(value),
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      }
    ],
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true,
      minWidth: 125
    },
    rowClassRules: {
      'opacity-50': params => !params.data.is_group_enabled
    },
    components: { CheckboxRenderer, AppNameRenderer },
    pagination: true,
    paginationPageSize: 10,
    getRowNodeId: data => data.app_id,
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
