import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { gridOptions } from './bidding_rules/grid_options'
import { putJson, getJson } from '../../helpers/request'
import { popModal } from '../../helpers/modal'
import { toPercentString } from '../../helpers/convert'

export default class extends Controller {
  static targets = [
    'grid',
    'modalPlaceholder',
    'roasTargetUpdateInput',
    'roasDxUpdateInput',
    'roasEnabledInput',
    'bulkUpdate'
  ]

  static values = {
    getRulesPath: String,
    bulkUpdateRulesPath: String,
    appId: String
  }

  async connect () {
    useDataGrid(this)

    this.newGrid(this.gridTarget, gridOptions(this), this.getRulesPathValue)

    this.disableTarget('bulkUpdate')
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  ruleTypeCountry () {
    return this.networkSelectTarget.classList.contains('hidden')
  }

  isNetworkSupportCountryTargeting () {
    return $(this.networkSelectTarget).find(':selected').data('support-country-targeting')
  }

  isRoas (bidType) {
    return ['roas_auto', 'roas_day', 'roas_single_value'].includes(bidType)
  }

  bulkUpdate = ev => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)

    const rows = gridApi.getSelectedRows()

    const bidding_rule_ids = rows.map(row => row.id)

    const count = rows.length

    const payload = {
      bidding_rule_ids,
      roas_dx: this.roasDxUpdateInputTarget.value,
      roas_target: this.roasTargetUpdateInputTarget.value / 100,
      enabled: this.roasEnabledInputTarget.checked
    }

    const description = `
      </br><strong>Autobid Roas Window:</strong> ${payload.roas_dx}</br>
      <strong>Autobid Roas Target:</strong> ${this.roasTargetUpdateInputTarget.value}</br>
      <strong>Active:</strong> ${payload.enabled}</br></br>
      <p>Are you sure?</p>`

    this.updateRules(payload, `Update ${count} Bidding Rule(s)`, description, `Update ${count}`)
  }

  updateRoasDay = ev => {
    const bidding_rule_ids = [ev.data.id]

    const payload = {
      bidding_rule_ids,
      roas_dx: ev.data.roas_dx,
      roas_target: ev.data.roas_target / 100,
      enabled: ev.data.enabled,
      roas_day: ev.data.roas_day
    }

    this.updateRules(payload, `Update Network Roas Window to ${ev.data.roas_day}`, 'Are you sure?', 'Update')
  }

  updateRules = (payload, title, description, label) => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)

    const action = async ({ target }, destroyModal) => {
      try {
        gridApi.showLoadingOverlay()
        await putJson(this.bulkUpdateRulesPathValue, payload, document.getElementsByClassName('modal-loader')[0])
        const data = await getJson(this.getRulesPathValue)
        gridApi.setRowData(data)
        this.toggleBulkActions()
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
        gridApi.hideOverlay()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title,
        description,
        color: 'green',
        actionButtonLabel: label
      },
      action
    )
  }

  getEstimatedD120Roas = (roasDx, roasTarget, data = {}) => {
    return toPercentString(((data.d120_and_organic * roasTarget) / data[roasDx]) * 100, 0)
  }

  getEstimatedBid = (roasDx, roasTarget, data) => {
    if (['default', 'auto'].includes(data.bid_type)) {
      const estimatedCpi = this.getCpi(data, roasDx, roasTarget)
      return !isNaN(estimatedCpi) ? estimatedCpi.toFixed(2) : '-'
    }

    if (['roas_day', 'roas_single_value', 'roas_auto'].includes(data.bid_type)) {
      const estimatedRoas = this.getRoas(data, roasDx, roasTarget)
      return toPercentString(estimatedRoas * 100, 0)
    }

    return '-'
  }

  getBid = (data) => {
    if (data.bid_type === 'default' && data.bid) {
      return data.bid.toFixed(2)
    }

    if (data.bid_type === 'default' && data.old_bid) {
      return data.old_bid.toFixed(2)
    }

    if (['roas_day', 'roas_single_value'].includes(data.bid_type) && !isNaN(data.old_roas)) {
      return toPercentString(data.old_roas * 100, 0)
    }

    if (['auto', 'roas_auto'].includes(data.bid_type) && data.old_budget) {
      return data.old_budget.toFixed(0)
    }

    return '-'
  }

  getRow = rowIndex => {
    return $(`div[row-index=${rowIndex}]`)[1]
  }

  getCell = (row, name) => {
    return row?.querySelectorAll(`div[col-id="${name}"]`)?.[0]?.children?.[0]?.children?.[0]
  }

  refreshRow = (roasDx, roasTarget, enabled, node) => {
    const row = this.getRow(node.rowIndex)

    if (!row) {
      return
    }

    const roasTargetCell = this.getCell(row, 'roas_target')
    const roasDxCell = this.getCell(row, 'roas_dx')
    const roasEnabled = this.getCell(row, 'enabled')
    const estimatedBidCell = this.getCell(row, 'estimated_bid')
    const estimatedD120RoasCell = this.getCell(row, 'estimated_d120_roas')

    if (roasDxCell) {
      roasDxCell.innerText = roasDx || '-'
    }

    if (roasTargetCell) {
      roasTargetCell.innerText = toPercentString(roasTarget, 0)
    }

    if (roasEnabled) {
      roasEnabled.innerText = enabled ? '✅' : '❌'
    }

    if (estimatedBidCell) {
      estimatedBidCell.innerText = this.getEstimatedBid(roasDx, roasTarget / 100, node.data)
    }

    if (estimatedD120RoasCell) {
      estimatedD120RoasCell.innerText = this.getEstimatedD120Roas(roasDx, roasTarget / 100, node.data)
    }
  }

  onUpdateChange = ev => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)
    const nodes = gridApi.getSelectedNodes()

    const roasDx = this.getBulkUpdateRoasDx()
    const roasTarget = this.getBulkUpdateRoasTarget()
    const roasEnabled = this.getBulkUpdateRoasEnabled()

    !isNaN(roasTarget) && gridApi.getSelectedNodes().length ? this.unableTarget('bulkUpdate') : this.disableTarget('bulkUpdate')

    nodes.forEach(node => this.refreshRow(roasDx, roasTarget, roasEnabled, node))
  }

  getBulkUpdateRoasTarget () {
    return parseFloat(this.roasTargetUpdateInputTarget.value)
  }

  getBulkUpdateRoasDx () {
    return this.roasDxUpdateInputTarget.value
  }

  getBulkUpdateRoasEnabled () {
    return this.roasEnabledInputTarget.checked
  }

  toggleBulkActions () {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)

    const selectedNodes = gridApi.getSelectedNodes()

    if (selectedNodes.length === 0 || this.getBulkUpdateRoasDx() === '' || isNaN(this.getBulkUpdateRoasTarget())) {
      this.disableTarget('bulkUpdate')
      return
    }

    this.unableTarget('bulkUpdate')
  }

  displaySelect = obj => {
    for (const [name, display] of Object.entries(obj)) {
      if (display) {
        this[`${name}SelectTarget`].classList.remove('hidden')
      } else {
        this[`${name}SelectTarget`].classList.add('hidden')
      }
    }
  }

  unableTarget (name) {
    this[`${name}Target`].classList.remove('opacity-60')
    this[`${name}Target`].disabled = false
  }

  disableTarget (name) {
    this[`${name}Target`].classList.add('opacity-60')
    this[`${name}Target`].disabled = true
  }

  getCpi (data, roasDx, roasTarget) {
    return data[roasDx] / roasTarget
  }

  getRoas (data, roasDx, roasTarget) {
    return data[data.roas_day] * roasTarget / data[roasDx]
  }

  isSelected (target) {
    return $(this[`${target}SelectTarget`]).find(':selected').val() !== ''
  }
}
