import { CheckboxRenderer } from '../../../grid_components/checkbox_renderer'
import { ActionButtons } from '../../../helpers/action_buttons'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        minWidth: 30
      },
      {
        headerName: 'Email',
        field: 'email',
        minWidth: 200
      },
      {
        headerName: 'Admin',
        field: 'admin',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.updateRolePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Add to Admins' : 'Remove to Admins',
            params.newValue ? 'Add' : 'Remove'
          )
      },
      {
        headerName: 'UA',
        field: 'ua',
        cellRenderer: 'CheckboxRenderer',
        onCellValueChanged: params =>
          controller.safeCheckbox(
            params,
            controller.updateRolePathValue,
            controller.modalPlaceholderTarget,
            params.newValue ? 'Add to Admins' : 'Remove to Admins',
            params.newValue ? 'Add' : 'Remove'
          )
      },
      {
        headerName: 'Actions',
        minWidth: 200,
        filter: false,
        cellRenderer: 'ActionButtons',
        cellRendererParams: {
          onDeleteButtonClick: params => controller.onDeleteButtonClick(params)
        }
      }
    ],
    defaultColDef: {
      filter: true,
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      autoHeight: true
    },
    pagination: true,
    paginationPageSize: 20,
    domLayout: 'autoHeight',
    components: { CheckboxRenderer, ActionButtons },
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
