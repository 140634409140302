import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { deleteJson, putJson } from '../../helpers/request'
import { popModal } from '../../helpers/modal'
import { gridOptions } from './index/grid_options'
import { getSelectValue } from '../../helpers/select'

export default class extends Controller {
  static targets = ['grid', 'modal', 'transition', 'closeButton', 'modalPlaceholder', 'experimentTypeSelect']
  static values = {
    getExperimentsPath: String,
    updateExperimentPath: String,
    startExperimentPath: String,
    stopExperimentPath: String,
    deleteExperimentPath: String
  }

  connect () {
    useDataGrid(this)
    this.newGrid(this.gridTarget, gridOptions(this), this.getExperimentsPathValue)
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  filterExperimentType = () => {
    const api = this.gridApisByTarget.get(this.gridTarget)
    const filter = api.getFilterInstance('experiment_type')
    const selectValue = getSelectValue(this.experimentTypeSelectTarget)
    console.log(selectValue)
    selectValue === 'All'
      ? filter.setModel(null)
      : filter.setModel({ type: 'equals', filter: selectValue })

    api.onFilterChanged()
  }

  onStartButtonClick = params => {
    const { id } = params.data

    const action = async ({ target }, destroyModal) => {
      try {
        const data = await putJson(this.startExperimentPathValue, { id }, target)
        params.node.setData(data)
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: 'Start Experiment',
        description: 'Are you sure?',
        color: 'green',
        actionButtonLabel: 'Start'
      },
      action
    )
  }

  onStopButtonClick = params => {
    const { id } = params.data

    const action = async ({ target }, destroyModal) => {
      try {
        const data = await putJson(this.stopExperimentPathValue, { id }, target)
        params.node.setData(data)
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: 'Stop Experiment',
        description: 'Are you sure?',
        color: 'red',
        actionButtonLabel: 'Stop'
      },
      action
    )
  }

  onDeleteButtonClick = params => {
    const { id } = params.data
    const action = async ({ target }, destroyModal) => {
      try {
        await deleteJson(this.deleteExperimentPathValue, { id }, target)
        params.api.applyTransaction({ remove: [params.data] })
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: 'Delete Experiment',
        description: 'Are you sure?',
        color: 'red',
        actionButtonLabel: 'Delete'
      },
      action
    )
  }
}
