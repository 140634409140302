import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { infoGridOptions } from './index/info_grid_options'
import { bidGridOptions } from './index/bid_grid_options'
import { budgetGridOptions } from './index/budget_grid_options'
import { getJson } from '../../helpers/request'

export default class extends Controller {
  static targets = [
    'infoGrid', 'bidGrid', 'budgetGrid', 'loadingSpinner', 'modal',
    'transition', 'closeButton', 'modalPlaceholder'
  ]

  static values = { getNetworksPath: String, networkUpdatePath: String }

  async connect () {
    useDataGrid(this)
    this.grids = await this.initializeGrids()
    await this.fetchNetworks()
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  initializeGrids = async () => {
    return await Promise.all(
      [
        [this.infoGridTarget, infoGridOptions],
        [this.bidGridTarget, bidGridOptions],
        [this.budgetGridTarget, budgetGridOptions]
      ].map(([target, options]) => {
        return this.newGrid(target, options(this))
      })
    )
  }

  fetchNetworks = async () => {
    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      await this.updateDataTables()
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }

  updateDataTables = async () => {
    this.grids.forEach(grid => grid.showLoadingOverlay())

    try {
      const data = await getJson(this.getNetworksPathValue)
      this.grids.forEach(grid => grid.setRowData(data))
    } catch {
      this.grids.forEach(grid => grid.hideOverlay())
    }
  }
}
