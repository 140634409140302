export class ExperimentStatusRenderer {
  init (params) {
    this.params = params

    const { can_be_started, is_running } = params.value

    const span = document.createElement('span')
    const text = can_be_started ? 'pending' : is_running ? 'running' : 'stopped'
    span.append(document.createTextNode(text))

    // Trick to make postCSS works and not wipe TailwindCSS classes
    const classes = {
      yellow: 'bg-yellow-100 text-yellow-800',
      green: 'bg-green-100 text-green-800',
      red: 'bg-red-100 text-red-800'
    }[can_be_started ? 'yellow' : is_running ? 'green' : 'red']

    span.className = `inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${classes}`
    this.eGui = span
  }

  getGui (params) {
    return this.eGui
  }

  destroy (params) {}
}
