import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { gridOptions } from './index/grid_options'
import { popModal } from '../../helpers/modal'
import { deleteJson } from '../../helpers/request'

export default class extends Controller {
  static targets = [
    'grid',
    'modalPlaceholder'
  ]

  static values = {
    getRolesPath: String,
    updateRolePath: String,
    deleteRolePath: String
  }

  connect () {
    useDataGrid(this)
    this.newGrid(this.gridTarget, gridOptions(this), this.getRolesPathValue)
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  onDeleteButtonClick = params => {
    const { id } = params.data
    const action = async ({ target }, destroyModal) => {
      try {
        await deleteJson(this.deleteRolePathValue, { id }, target)
        params.api.applyTransaction({ remove: [params.data] })
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: 'Delete Role',
        description: 'Are you sure?',
        color: 'red',
        actionButtonLabel: 'Delete'
      },
      action
    )
  }
}
