import { Controller } from 'stimulus'
import { enter, leave } from '../helpers/transition'

export default class extends Controller {
  static targets = ['transition']

  async onClick () {
    if (this.transitionTarget.classList.contains('hidden')) { await enter(this.transitionTarget) } else await leave(this.transitionTarget)
  }
}
