import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        this.select.select2(this.options)

        // Select2 does not use ‘native’ events. They have their own event framework.
        // So we capture the Select2 event and re-fire a native event. Then we can
        // use regular data-action from the DOM :]
        this.select.on('select2:select select2:unselect', () => {
            const event = new Event('change', { bubbles: true })
            this.element.dispatchEvent(event)
        })
    }

    get select() {
        return $(this.element)
    }

    get options() {
        const data = this.select.data();
        let options = {};

        for (const item in data) {
            if (item.indexOf('select2') > -1) {
                options = { ...options, [this.parseSelect2OptionName(item)]: data[item] };
            }
        }

        return options;
    }

    parseSelect2OptionName = (item) => {
        item = item.slice(7)
        return item.charAt(0).toLowerCase() + item.slice(1)
    }

    onTurbolinksBeforeCache() {
        this.select.select2('destroy')
    }
}
