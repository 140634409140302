export class ActionButtons {
  init (params) {
    this.params = params

    const div = document.createElement('div')
    div.className += 'flex items-center h-full'

    if (typeof this.params.onEditButtonClick === 'function') {
      this.editButton = document.createElement('button')
      this.editButton.className +=
        'inline-flex items-center h-8 bg-green-500 hover:bg-green-400 text-white font-bold px-4 border-b-4 border-green-700 hover:border-green-500 focus:outline-none focus:shadow-outline-blue focus:border-green-600 active:bg-green-600 transition duration-150 ease-in-out rounded'
      this.editButton.innerHTML = 'Edit'
      this.editButton.addEventListener('click', ev => this.onEditButtonClick(ev))
      div.appendChild(this.editButton)
    }

    if (typeof this.params.onDeleteButtonClick === 'function') {
      this.deleteButton = document.createElement('button')
      this.deleteButton.className +=
        'ml-2 inline-flex items-center h-8 bg-red-500 hover:bg-red-400 text-white font-bold px-4 border-b-4 border-red-700 hover:border-red-500 focus:outline-none focus:shadow-outline-blue focus:border-red-600 active:bg-red-600 transition duration-150 ease-in-out rounded'
      this.deleteButton.innerHTML = 'Delete'
      this.deleteButton.addEventListener('click', ev => this.onDeleteButtonClick(ev))
      div.appendChild(this.deleteButton)
    }

    this.eGui = div
    this.params.element = this.eGui
  }

  getGui () {
    return this.eGui
  }

  destroy () {
    this.deleteButton.removeEventListener('click', ev => this.onDeleteButtonClick(ev))
  }

  onDeleteButtonClick () {
    this.params.stopButton = this.stopButton
    this.params.onDeleteButtonClick(this.params)
  }

  onEditButtonClick () {
    this.params.stopButton = this.stopButton
    this.params.onEditButtonClick(this.params)
  }
}
