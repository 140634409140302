import { Controller } from 'stimulus'
import { enter, leave } from '../helpers/transition'

export default class extends Controller {
  static targets = ['modal', 'transition', 'closeButton']

  async connect () {
    await Promise.all(this.transitionTargets.map(enter))

    this.handleModalClosing(this.modalTarget, this.closeButtonTargets, async () => {
      await Promise.all(this.transitionTargets.map(leave))
      const placeholder = this.element.parentElement
      placeholder.removeChild(placeholder.lastElementChild)
    })
  }

  handleModalClosing (modalElement, closeElements, onClose) {
    const onClick = ev => {
      if (!modalElement.contains(ev.target)) onClose()
    }

    const onEscapeKeydown = ev => {
      if (ev.key === 'Esc' || ev.key === 'Escape') onClose()
    }

    document.addEventListener('click', onClick, { once: true })
    document.addEventListener('keydown', onEscapeKeydown, { once: true })
    closeElements.forEach(element => element.addEventListener('click', onClose, { once: true }))
  }
}
