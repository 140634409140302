import { toPercentString, toRankString, toBooleanString } from '../../../helpers/convert'

export const installsGridOptions = controller => {
  const switchback = controller.type === 'switchback'
  const prefix = switchback ? 'mean_' : ''

  return {
    columnDefs: [
      {
        headerName: 'Rank',
        field: 'rank',
        width: 100,
        cellRenderer: ({ value }) => toRankString(value)
      },
      {
        headerName: 'Group',
        field: 'group_id',
        width: 100,
        hide: switchback
      },
      {
        headerName: 'Version',
        field: 'version',
        width: 120
      },
      {
        headerName: 'Info',
        field: 'version_info',
        width: 500,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
        hide: switchback
      },
      {
        headerName: 'MO %',
        field: 'margin_objective',
        width: 100,
        cellRenderer: ({ value }) => toPercentString(value)
      },
      {
        headerName: 'Enabled?',
        field: 'is_enabled',
        width: 150,
        cellRenderer: ({ value }) => toBooleanString(value),
        hide: switchback
      },
      {
        headerName: 'Before',
        field: `${prefix}daily_installs_before`,
        width: 160
      },
      {
        headerName: 'After',
        field: `${prefix}daily_installs_after`,
        width: 160
      },
      {
        headerName: 'Change Δ',
        field: `${prefix}installs_change`,
        width: 160,
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: 'Change Δ %',
        field: `${prefix}installs_change_percent`,
        width: 160,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      },
      {
        headerName: '⇅ Change Δ %',
        field: `${prefix}relative_installs_change_percent`,
        width: 160,
        cellRenderer: ({ value }) => toPercentString(value),
        cellClassRules: {
          'text-green-500': params => params.value > 0,
          'text-red-500': params => params.value < 0
        }
      }
    ],
    rowClassRules: {
      'opacity-50': params => !params.data.is_enabled,
      'border-yellow-300 bg-yellow-50 border-dashed': params => params.data.is_control_group
    },
    defaultColDef: {
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true
    },
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
