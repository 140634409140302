import { ActionButtons } from './action_buttons'
import { ExperimentStatusRenderer } from './experiment_status_renderer'
import { ExperimentVersionsRenderer } from './experiment_versions_renderer'
import { ExperimentGroupsRenderer } from './experiment_groups_renderer'
import { toPercentString, toLocaleDateString } from '../../../helpers/convert'

export const gridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
        cellRenderer: ({ value }) => {
          return `
                                <div class="flex items-center">
                                    ${value} 
                                    <a href="/experiments/${value}" data-turbolinks="false" class="cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">                     
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </a>
                                </div>
                           `
        }
      },
      {
        headerName: 'Type',
        field: 'experiment_type',
        filter: true,
        width: 100
      },
      {
        headerName: 'Purpose',
        field: 'purpose',
        width: 300,
        editable: true,
        filter: true,
        cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word' },
        onCellValueChanged: ev => controller.updateRecord(ev, controller.updateExperimentPathValue)
      },
      {
        headerName: 'Data Type',
        field: 'margin_data_type',
        width: 100
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'ExperimentStatusRenderer',
        filter: true,
        width: 150
      },
      {
        headerName: 'Games',
        field: 'total_games',
        filter: 'agNumberColumnFilter',
        width: 100
      },
      {
        headerName: 'Discrepancy',
        field: 'worst_discrepancy',
        filter: 'agNumberColumnFilter',
        width: 120,
        cellRenderer: ({ value }) => toPercentString(value)
      },
      {
        headerName: 'Versions',
        field: 'experiment_versions',
        cellRenderer: 'ExperimentVersionsRenderer',
        width: 180
      },
      {
        headerName: 'Groups',
        field: 'experiment_groups',
        cellRenderer: 'ExperimentGroupsRenderer',
        width: 300
      },
      {
        headerName: 'Actions',
        field: 'status',
        width: 220,
        cellRenderer: 'ActionButtons',
        cellRendererParams: {
          onStartButtonClick: controller.onStartButtonClick,
          onStopButtonClick: controller.onStopButtonClick,
          onDeleteButtonClick: controller.onDeleteButtonClick
        }
      },
      {
        headerName: 'Started At',
        field: 'started_at',
        width: 120,
        cellRenderer: ({ value }) => toLocaleDateString(value)
      },
      {
        headerName: 'Stopped At',
        field: 'stopped_at',
        width: 120,
        cellRenderer: ({ value }) => toLocaleDateString(value)
      }
    ],
    components: {
      ActionButtons,
      ExperimentStatusRenderer,
      ExperimentVersionsRenderer,
      ExperimentGroupsRenderer
    },
    defaultColDef: {
      floatingFilter: true,
      suppressMenu: true,
      sortable: true,
      autoHeight: true
    },
    animateRows: true,
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    getRowNodeId: data => data.id,
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
