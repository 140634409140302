import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { getSelectValue } from '../../helpers/select'
import { excludePeriodsGridOptions } from './show/exclude_periods_grid_options'
import { groupsGridOptions } from './show/groups_grid_options'
import { relativeGridOptions } from './show/relative_grid_options'
import { spendsGridOptions } from './show/spends_grid_options'
import { installsGridOptions } from './show/installs_grid_options'
import { organicInstallsGridOptions } from './show/organic_installs_grid_options'
import { marginsGridOptions } from './show/margins_grid_options'
import { calendarMarginsGridOptions } from './show/calendar_margins_grid_options'
import { summaryGridOptions } from './show/summary_grid_options'
import { getJson, deleteJson } from '../../helpers/request'
import { popModal } from '../../helpers/modal'
import { updateCharts } from './show/chart_logic'

export default class extends Controller {
  static targets = [
    // Grids
    'excludePeriodsGrid',
    'experimentGroupsGrid',
    'summaryGrid',
    'marginsGrid',
    'calendarMarginsGrid',
    'relativeGrid',
    'spendsGrid',
    'installsGrid',
    'organicInstallsGrid',
    // Charts
    'spendsChart',
    'installsChart',
    'organicInstallsChart',
    'marginsChart',
    'calendarMarginsChart',
    // Dropdowns
    'globalLevelCountryGroupSelect',
    'globalLevelPlatformSelect',
    'globalLevelModelWaitDaysSelect',
    // Labels
    'fromDateBefore',
    'toDateBefore',
    'daysBefore',
    'fromDateAfter',
    'toDateAfter',
    'daysAfter',
    // Misc
    'modalPlaceholder',
    'loadingSpinner'
  ]

  static values = {
    updateExperimentGroupPath: String,
    experimentGroupsPath: String,
    experimentResultsPath: String,
    experimentDayToDayResultsPath: String,
    experimentExcludePeriodsPath: String,
    deleteExcludePeriodPath: String,
    experimentType: String
  }

  async connect () {
    useDataGrid(this)

    this.grids = await this.initializeGlobalLevelResultGrids()

    await Promise.all([
      this.newGrid(this.experimentGroupsGridTarget, groupsGridOptions(this), this.experimentGroupsPathValue),
      this.newGrid(this.excludePeriodsGridTarget, excludePeriodsGridOptions(this), this.experimentExcludePeriodsPathValue),
      this.updateGlobalLevelExperimentResults(null, false)
    ])
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  get type () {
    return this.experimentTypeValue
  }

  updateGlobalLevelExperimentResults = async (_, refreshCache = false) => {
    this.loadingSpinnerTarget.classList.add('animate-spin')
    this.loadingSpinnerTarget.classList.remove('hidden')

    try {
      await Promise.all([this.updateDataTables(refreshCache), this.updateGlobalLevelCharts(refreshCache)])
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingSpinnerTarget.classList.add('hidden')
      this.loadingSpinnerTarget.classList.remove('animate-spin')
    }
  }

  onReloadResultButtonClick = async () => {
    await this.updateGlobalLevelExperimentResults(null, true)
  }

  initializeGlobalLevelResultGrids = async () => {
    return await Promise.all(
      [
        [this.summaryGridTarget, summaryGridOptions],
        [this.relativeGridTarget, relativeGridOptions],
        [this.spendsGridTarget, spendsGridOptions],
        [this.installsGridTarget, installsGridOptions],
        [this.organicInstallsGridTarget, organicInstallsGridOptions],
        [this.marginsGridTarget, marginsGridOptions],
        [this.calendarMarginsGridTarget, calendarMarginsGridOptions]
      ].map(([target, options]) => this.newGrid(target, options(this)))
    )
  }

  updateDataTables = async (refreshCache) => {
    const mutationObserver = new MutationObserver(
      () => this.grids.forEach(grid => grid.sizeColumnsToFit()))
    mutationObserver.observe(this.summaryGridTarget, { attributes: true })

    this.grids.forEach(grid => grid.showLoadingOverlay())

    try {
      const params = {
        refresh_cache: refreshCache,
        platform: getSelectValue(this.globalLevelPlatformSelectTarget),
        country_group: getSelectValue(this.globalLevelCountryGroupSelectTarget),
        model_wait_days: getSelectValue(this.globalLevelModelWaitDaysSelectTarget)
      }
      const data = await getJson(this.experimentResultsPath(params))

      if (data.length > 0) {
        const {
          from_date_before,
          to_date_before,
          ndays_before,
          from_date_after,
          to_date_after,
          ndays_after
        } = data[0]

        this.fromDateBeforeTarget.innerHTML = from_date_before
        this.toDateBeforeTarget.innerHTML = to_date_before
        this.daysBeforeTarget.innerHTML = ndays_before

        this.fromDateAfterTarget.innerHTML = from_date_after
        this.toDateAfterTarget.innerHTML = to_date_after
        this.daysAfterTarget.innerHTML = ndays_after
      }

      this.grids.forEach(grid => grid.setRowData(data))
      this.avoidGridHeightResizeOnDataChange()
    } catch {
      this.grids.forEach(grid => grid.hideOverlay())
    }
  }

  updateGlobalLevelCharts = async (refreshCache) => {
    const fetchExcludePeriods = () => getJson(this.experimentExcludePeriodsPathValue)
    const fetchResults = () => getJson(
      this.experimentDayToDayResultsPath({
        refresh_cache: refreshCache,
        platform: getSelectValue(this.globalLevelPlatformSelectTarget),
        country_group: getSelectValue(this.globalLevelCountryGroupSelectTarget),
        model_wait_days: getSelectValue(this.globalLevelModelWaitDaysSelectTarget)
      })
    )

    await updateCharts(
      'experiments-ShowGlobalLevelTarget',
      this.chartDefinitions,
      fetchExcludePeriods,
      fetchResults
    )
  }

  onDeleteExcludePeriodButtonClick = params => {
    const { id } = params.data

    const action = async ({ target }, destroyModal) => {
      try {
        await deleteJson(this.deleteExcludePeriodPathValue, { id }, target)
        params.api.applyTransaction({ remove: [params.data] })
        await this.updateGlobalLevelCharts(true)
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: 'Delete Exclude Period',
        description: 'Are you sure?',
        color: 'red',
        actionButtonLabel: 'Delete'
      },
      action
    )
  }

  /*
     * We use autoHeight feature of grid so the grid take the needed height when loading data.
     * But, when updating new data, it implies that the grid resize in height (because its height is
     * not fixed in the dom), causing a layout shift. The trick here is to use the height of the first
     * rendered grid (relativeGridTarget) and apply its computed height to all other grids to fix the
     * height. As the number of groups is fixed, its going to be the same height even when data are
     * updated when a user change a value in a dropdown.
     */
  avoidGridHeightResizeOnDataChange () {
    $(this.relativeGridTarget).height($(this.relativeGridTarget).height())
    $(this.marginsGridTarget).height($(this.relativeGridTarget).height())
    $(this.calendarMarginsGridTarget).height($(this.relativeGridTarget).height())
    $(this.summaryGridTarget).height($(this.relativeGridTarget).height())
    $(this.spendsGridTarget).height($(this.relativeGridTarget).height())
    $(this.installsGridTarget).height($(this.relativeGridTarget).height())
    $(this.organicInstallsGridTarget).height($(this.relativeGridTarget).height())
  }

  get chartDefinitions () {
    const prefix = this.type === 'switchback' ? 'mean_' : ''

    return [
      [this.marginsChartTarget, `${prefix}predicted_margin`],
      [this.calendarMarginsChartTarget, `${prefix}calendar_margin`],
      [this.spendsChartTarget, `${prefix}total_spends`],
      [this.installsChartTarget, `${prefix}total_installs`],
      [this.organicInstallsChartTarget, `${prefix}organic_installs`]
    ].map(([placeholder, attr]) => ({
      placeholder,
      mapper: data => data.map(o => ({ x: o.install_date, y: o[attr] }))
    }))
  }

  experimentResultsPath (queryParams) {
    return this.experimentResultsPathValue + '?' + new URLSearchParams(queryParams)
  }

  experimentDayToDayResultsPath (queryParams) {
    return this.experimentDayToDayResultsPathValue + '?' + new URLSearchParams(queryParams)
  }
}
