import { toPercentString } from '../../../helpers/convert'

export const versionsGridOptions = controller => {
  return {
    columnDefs: [
      {
        headerName: 'Version',
        field: 'autobid_version'
      },
      {
        headerName: 'Control',
        field: 'is_control_group'
      },
      {
        headerName: 'Margin Objective',
        field: 'margin_objective',
        cellRenderer: ({ value }) => toPercentString(value)
      }
    ],
    defaultColDef: {
      suppressMenu: true,
      filter: 'agTextColumnFilter',
      sortable: true,
      autoHeight: true
    },
    domLayout: 'autoHeight',
    enableCellTextSelection: true,
    ensureDomOrder: true
  }
}
