export class ActionButtons {
  init (params) {
    this.params = params

    const { can_be_started, can_be_stopped, can_be_deleted } = params.value
    const div = document.createElement('div')
    div.className = 'flex flex-wrap pt-2'

    if (can_be_started) {
      this.startButton = document.createElement('button')
      this.startButton.className +=
        'inline-flex h-8 items-center bg-green-500 hover:bg-green-400 text-white font-bold px-4 border-b-4 border-green-700 hover:border-green-500 focus:outline-none focus:shadow-outline-blue focus:border-green-600 active:bg-green-600 transition duration-150 ease-in-out rounded mr-1'
      this.startButton.innerText = 'Start'
      this.startButton.addEventListener('click', ev => this.onStartButtonClick(ev))
      div.appendChild(this.startButton)
    }

    if (can_be_stopped) {
      this.stopButton = document.createElement('button')
      this.stopButton.className +=
        'inline-flex h-8 items-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold px-4 border-b-4 border-indigo-700 hover:border-indigo-500 focus:outline-none focus:shadow-outline-blue focus:border-indigo-600 active:bg-indigo-600 transition duration-150 ease-in-out rounded mr-1'
      this.stopButton.innerText = 'Stop'
      this.stopButton.addEventListener('click', ev => this.onStopButtonClick(ev))
      div.appendChild(this.stopButton)
    }

    if (can_be_deleted) {
      this.deleteButton = document.createElement('button')
      this.deleteButton.className +=
        'inline-flex h-8 items-center bg-indigo-500 hover:bg-indigo-400 text-white font-bold px-4 border-b-4 border-indigo-700 hover:border-indigo-500 focus:outline-none focus:shadow-outline-blue focus:border-indigo-600 active:bg-indigo-600 transition duration-150 ease-in-out rounded'
      this.deleteButton.innerText = 'Delete'
      this.deleteButton.addEventListener('click', ev => this.onDeleteButtonClick(ev))
      div.appendChild(this.deleteButton)
    }

    this.eGui = div
    this.params.element = this.eGui
  }

  getGui () {
    return this.eGui
  }

  destroy () {
    if (this.startButton) { this.startButton.removeEventListener('click', ev => this.onStartButtonClick(ev)) }
    if (this.stopButton) { this.stopButton.removeEventListener('click', ev => this.onStopButtonClick(ev)) }
    if (this.deleteButton) { this.deleteButton.removeEventListener('click', ev => this.onDeleteButtonClick(ev)) }
  }

  onStartButtonClick () {
    this.params.onStartButtonClick(this.params)
  }

  onStopButtonClick () {
    this.params.onStopButtonClick(this.params)
  }

  onDeleteButtonClick () {
    this.params.onDeleteButtonClick(this.params)
  }
}
