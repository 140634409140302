export const triggerNotification = notification => {
  document.getElementById('notifications').insertAdjacentHTML('afterbegin', notification)
}

export const createNotification = (title, body) => {
  return `<div data-controller="notification"
     class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto hidden">
  <div class="rounded-lg shadow-xs overflow-hidden">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <div class="h-6 w-6 text-red-800">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm leading-5 font-medium text-gray-900">
            ${title}
          </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              ${body}
            </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button data-action="notification#close"
                  class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
            <i class="h-5 w-5 fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>`
}
