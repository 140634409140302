import { Controller } from 'stimulus'
import { useDataGrid } from '../mixins/datagrid'
import { gridOptions } from './ltv_correction_rules/grid_options'
import { deleteJson, postJson, putJson, getJson } from '../../helpers/request'
import { popModal } from '../../helpers/modal'
import { handleDisabledButtonState } from '../../helpers/button_states'
import { toPercentString } from '../../helpers/convert'

export default class extends Controller {
  static targets = [
    'grid',
    'modalPlaceholder',
    'countrySelect',
    'levelSelect',
    'networkSelect',
    'campaignSelect',
    'baseLtvCorrectionInput',
    'targetLtvCorrectionInput',
    'targetLtvCorrectionUpdateInput',
    'computedScaleFactorInput',
    'estimatedCpiInput',
    'addButton',
    'bulkUpdate',
    'bulkDelete'
  ]

  static values = {
    updateGamePath: String,
    getRulesPath: String,
    getRemainingActiveCampaignsPath: String,
    createRulePath: String,
    bulkUpdateRulesPath: String,
    bulkDeleteRulesPath: String,
    appLevelLtvCorrection: String,
    appId: String
  }

  async connect () {
    useDataGrid(this)

    this.newGrid(this.gridTarget, gridOptions(this), this.getRulesPathValue)

    this.disableBulkActions()
  }

  onTurbolinksBeforeCache () {
    this.destroyAllGrids()
  }

  ruleTypeCountry () {
    return this.networkSelectTarget.classList.contains('hidden')
  }

  isNetworkSupportCountryTargeting () {
    return $(this.networkSelectTarget).find(':selected').data('support-country-targeting')
  }

  async fetchCampaigns () {
    const networkId = this.networkSelectTarget.value

    const campaigns = !networkId
      ? []
      : await getJson(
      `${this.getRemainingActiveCampaignsPathValue}?network_id=${networkId}&game_id=${this.appIdValue}`
      )

    $(this.campaignSelectTarget).find('option').remove()
    this.campaignSelectTarget.append(new Option('Select a campaign', '', false, false))

    for (const campaign of campaigns) {
      const option = new Option(campaign.campaign_name, campaign.campaign_id, false, false)

      this.campaignSelectTarget.append(option)
    }
  }

  computeLevel (payload) {
    const selectedLevel = this.levelSelectTarget.value

    if (selectedLevel === 'country') {
      return payload.country_code ? 'country' : 'country_group'
    }

    if (selectedLevel === 'network') {
      return payload.country_code ? 'country_network' : 'country_group_network'
    }

    if (selectedLevel === 'campaign') {
      return 'campaign'
    }

    return null
  }

  getRow = rowIndex => {
    return $(`div[row-index=${rowIndex}]`)[1]
  }

  getCell = (row, name) => {
    return row.querySelectorAll(`div[col-id="${name}"]`)[0]
  }

  refreshRow = (targetLtvCorrection, node) => {
    const row = this.getRow(node.rowIndex)

    const targetLtvCorrectionCell = this.getCell(row, 'ltv_correction')

    if (targetLtvCorrectionCell) {
      targetLtvCorrectionCell.innerText = toPercentString(targetLtvCorrection)
    }
  }

  onLtvCorrectionUpdateChange = ev => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)
    const nodes = gridApi.getSelectedNodes()

    const targetLtvCorrection = this.getBulkUpdateLtvCorrection()

    !isNaN(targetLtvCorrection) ? this.unableTarget('bulkUpdate') : this.disableTarget('bulkUpdate')

    nodes.forEach(node => this.refreshRow(targetLtvCorrection, node))
  }

  getBulkUpdateLtvCorrection () {
    return parseFloat(this.targetLtvCorrectionUpdateInputTarget.value)
  }

  addRule = async ev => {
    const payload = {
      autobid_game_id: this.appIdValue,
      ltv_correction: this.targetLtvCorrectionInputTarget.value / 100
    }

    const isCountryGroup = $(this.countrySelectTarget).find(':selected').data('is-country-group')
    if (isCountryGroup) { payload.country_group = $(this.countrySelectTarget).val() } else { payload.country_code = $(this.countrySelectTarget).val() }

    const networkSelectTargetValue = $(this.networkSelectTarget).val()
    if (networkSelectTargetValue !== '') {
      payload.network_id = networkSelectTargetValue
      payload.support_country_targeting = this.isNetworkSupportCountryTargeting()
    }

    const campaignSelectTargetValue = $(this.campaignSelectTarget).val()
    if (campaignSelectTargetValue !== '') {
      payload.campaign_id = campaignSelectTargetValue
      payload.campaign_name = $(this.campaignSelectTarget).find(':selected').text()
      payload.country_code = 'ALL'
    }

    payload.level = this.computeLevel(payload)

    const data = await postJson(this.createRulePathValue, payload, ev.target)
    this.gridApisByTarget.get(this.gridTarget).applyTransaction({ add: [data], addIndex: 0 })
  }

  bulkDelete = ev => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)

    const rows = gridApi.getSelectedRows()
    const ids = rows.map(row => row.id)
    const count = rows.length

    const action = async ({ target }, destroyModal) => {
      try {
        gridApi.showLoadingOverlay()
        await deleteJson(this.bulkDeleteRulesPathValue, { ids }, target)
        const data = await getJson(this.getRulesPathValue)
        gridApi.setRowData(data)
        this.toggleBulkActions()
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
        gridApi.hideOverlay()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: `Delete ${count} LTV Correction Rule(s)`,
        description: 'Are you sure?',
        color: 'red',
        actionButtonLabel: `Delete ${count}`
      },
      action
    )
  }

  bulkUpdate = ev => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)

    const rows = gridApi.getSelectedRows()
    const ids = rows.map(row => row.id)
    const count = rows.length

    const payload = {
      ids,
      ltv_correction: this.getBulkUpdateLtvCorrection() / 100
    }

    const action = async ({ target }, destroyModal) => {
      try {
        gridApi.showLoadingOverlay()
        await putJson(this.bulkUpdateRulesPathValue, payload, ev.target)
        const data = await getJson(this.getRulesPathValue)
        gridApi.setRowData(data)
        this.targetLtvCorrectionUpdateInputTarget.value = ''
        this.toggleBulkActions()
      } catch (error) {
        console.log(error)
      } finally {
        destroyModal()
        gridApi.hideOverlay()
      }
    }

    popModal(
      this.modalPlaceholderTarget,
      {
        title: `Update ${count} LTV Correction Rule(s)`,
        description: 'Are you sure?',
        color: 'green',
        actionButtonLabel: `Update ${count}`
      },
      action
    )
  }

  updateBase = async ev => {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)
    const ltvCorrection = this.baseLtvCorrectionInputTarget.value

    gridApi.showLoadingOverlay()
    try {
      const payload = { id: this.appIdValue, ltv_correction: ltvCorrection }
      await putJson(this.updateGamePathValue, payload, ev.target)

      this.appLevelLtvCorrectionValue = ltvCorrection

      const data = await getJson(this.getRulesPathValue)
      gridApi.setRowData(data)
    } catch (error) {
      console.log(error)
    } finally {
      gridApi.hideOverlay()
    }
  }

  disableBulkActions () {
    this.disableTarget('targetLtvCorrectionUpdateInput')
    this.disableTarget('bulkUpdate')
    this.disableTarget('bulkDelete')
  }

  toggleBulkActions () {
    const gridApi = this.gridApisByTarget.get(this.gridTarget)
    const targetLtvCorrection = parseFloat(this.targetLtvCorrectionUpdateInputTarget.value)

    if (gridApi.getSelectedNodes().length) {
      this.unableTarget('targetLtvCorrectionUpdateInput')
      this.unableTarget('bulkDelete')

      !isNaN(targetLtvCorrection) ? this.unableTarget('bulkUpdate') : this.disableTarget('bulkUpdate')
    } else {
      this.disableBulkActions()
    }
  }

  resetSelection () {
    this.networkSelectTarget.selectedIndex = 0
    this.campaignSelectTarget.selectedIndex = 0
    this.countrySelectTarget.selectedIndex = 0
  }

  displaySelect = obj => {
    for (const [name, display] of Object.entries(obj)) {
      if (display) {
        this[`${name}SelectTarget`].classList.remove('hidden')
      } else {
        this[`${name}SelectTarget`].classList.add('hidden')
      }
    }
  }

  changeSelectDisplay () {
    const level = this.levelSelectTarget.value

    switch (level) {
      case 'country':
        this.displaySelect({ country: true, network: false, campaign: false })
        break
      case 'network':
        this.displaySelect({ country: true, network: true, campaign: false })
        break
      case 'campaign':
        this.displaySelect({ country: false, network: true, campaign: true })
        break
    }

    this.resetSelection()
  }

  onLevelChange = ev => {
    this.changeSelectDisplay()
    this.onNetworkChange(ev)
  }

  onCountryChange = ev => {
    this.updateAddButtonState()
  }

  onNetworkChange = async (ev) => {
    if (!this.networkSelectTarget.value) {
      this.removeOptionAll()
      this.ruleTypeCountry() ? this.unableTarget('countrySelect') : this.disableTarget('countrySelect')
      this.disableTarget('campaignSelect')
    } else if (this.isNetworkSupportCountryTargeting()) {
      this.unableTarget('countrySelect')
      this.unableTarget('campaignSelect')
      this.removeOptionAll()
    } else {
      this.addOptionAll()
      this.disableTarget('countrySelect')
      this.unableTarget('campaignSelect')
    }

    await this.fetchCampaigns()

    this.updateAddButtonState()
  }

  onCampaignChange = ev => {
    this.updateAddButtonState()
  }

  unableTarget (name) {
    this[`${name}Target`].classList.remove('opacity-60')
    this[`${name}Target`].disabled = false
  }

  disableTarget (name) {
    this[`${name}Target`].classList.add('opacity-60')
    this[`${name}Target`].disabled = true
  }

  addOptionAll () {
    const optionAll = this.countrySelectTarget.children.namedItem('option_all')

    if (optionAll) {
      return
    }

    const newOptionAll = new Option('ALL', 'ALL', true, true)
    newOptionAll.setAttribute('id', 'option_all')
    this.countrySelectTarget.append(newOptionAll)
  }

  removeOptionAll () {
    const optionAll = this.countrySelectTarget.children.namedItem('option_all')

    if (!optionAll) {
      return
    }

    optionAll.remove()
  }

  isSelected (target) {
    return $(this[`${target}SelectTarget`]).find(':selected').val() !== ''
  }

  addButtonShouldBeDisabled () {
    const hasLtvCorrection = parseFloat(this.targetLtvCorrectionInputTarget.value)

    if (isNaN(hasLtvCorrection)) {
      return true
    }

    const level = this.levelSelectTarget.value

    if (level === 'country' && this.isSelected('country')) {
      return false
    }

    if (level === 'network' && this.isSelected('network') && this.isSelected('country')) {
      return false
    }

    if (level === 'campaign' && this.isSelected('campaign') && this.isSelected('network')) {
      return false
    }

    return true
  }

  updateAddButtonState () {
    handleDisabledButtonState(this.addButtonTarget, this.addButtonShouldBeDisabled())
  }
}
