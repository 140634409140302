// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.(ts|js)$/)

/** The native StimulusJS API call disconnect() in the following cases:
 *  https://stimulus.hotwire.dev/reference/lifecycle-callbacks#disconnection
 *  To handle Turbolinks caching behaviour gracefully (especially for back-button),
 *  we also want a method to be called in case of `turbolinks:before-cache` event.
 */
document.addEventListener('turbolinks:before-cache', () => {
  application.controllers.forEach(controller => {
    if (typeof controller.onTurbolinksBeforeCache === 'function') {
      controller.onTurbolinksBeforeCache()
    }
  })
})

application.load(definitionsFromContext(context))
